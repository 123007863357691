import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { GetBrokerById } from 'services/apiServices';
import { Errors, GetBrokerByIdResult } from 'types/ApiServicesTypes';

export default function useGetBrokerById(id: number) {
  const { showAlert } = useContext(ToastContext);
  const objQuery = useQuery<GetBrokerByIdResult, Errors>(
    ['GetBrokerById', id],
    () => GetBrokerById(id),
    { enabled: !!id },
  );
  if (objQuery.isError && objQuery.error) {
    showAlert!(objQuery.error.message);
  }

  return objQuery;
}
