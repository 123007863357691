import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { Box } from '@mui/material';
import { BrokerById } from 'types/ApiServicesTypes';
import {
  useGetMTVPSTErminal,
  useGetMTVPSTErminalStatus,
} from 'services/Menu/useGetMTVPSTerminal';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import useGetWebHookAuthKey from 'services/Bots/useGetWebHookAuthKey';
import useGetProfile from 'services/Menu/useGetProfile';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import { openInNewTab } from 'utils/GenericFunctions';
import { ToastContext } from '../Context/ToastContext';

const MtVpsTerminal = ({
  brokerData,
}: {
  brokerData: BrokerById | undefined;
}): JSX.Element => {
  const isMTVPSTerminal = useIsFeatureFlag([FEATURES.IS_MT_VPS_TERMINAL]);
  const isMt5 = brokerData && brokerData['environment'] == 'live-mt5';
  const [is_active, setisActive] = useState(false);
  const { id } = useParams<{ id: string }>();
  const Terminalmutation = useGetMTVPSTErminal();
  const routeChange = (
    url: string,
    user_id: number,
    ubi_id: number,
    auth_key: string,
  ) => {
    const path = `${url}/metatrader5/${user_id}/${ubi_id}/${auth_key}/terminal/`;
    openInNewTab(path);
  };
  const { data } = useGetMTVPSTErminalStatus(parseInt(id), 'live-mt5');
  const { data: webhookurl } = useGetWebHookAuthKey();
  const { data: profileDetails } = useGetProfile();
  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    setisActive(data ? data.success : false);
  }, [data]);

  if (!isMTVPSTerminal || !isMt5) {
    return <></>;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        margin: '10px',
        justifyContent: 'center',
      }}
      className="m-main-spacing"
    >
      <MuiLoadingButton
        fullWidth={false}
        sx={{
          mr: { xs: 0, md: 2 },
        }}
        variant="outlined"
        color={is_active ? 'error' : 'success'}
        loading={Terminalmutation.isLoading}
        loadingPosition="center"
        onClick={() => {
          Terminalmutation.mutate(
            { ubiId: parseInt(id), key: is_active ? 'terminate' : 'deploy' },
            {
              onSuccess: res => {
                if (res.success) {
                  setisActive(!is_active);
                }
              },
            },
          );
        }}
      >
        <FormattedMessage
          id={
            is_active
              ? 'buttons.stop_mt_vps_terminal'
              : 'buttons.start_mt_vps_terminal'
          }
        />
      </MuiLoadingButton>
      <MuiButton
        fullWidth={false}
        sx={{
          mr: { xs: 0, md: 2 },
        }}
        variant="contained"
        color="secondary"
        onClick={() => {
          if (!is_active) {
            showAlert!(
              'Please start the MT VPS Terminal before connecting',
              'warning',
            );
          } else if (profileDetails?.user_id && webhookurl?.key) {
            routeChange(
              process.env.REACT_APP_MT_VPS_TERMINAL_URL ||
                'https://dev-app.speedbot.tech',
              parseInt(profileDetails.user_id),
              parseInt(id),
              webhookurl.key,
            );
          }
        }}
      >
        <FormattedMessage id="buttons.connect_mt_vps_terminal" />
      </MuiButton>
    </Box>
  );
};
export default MtVpsTerminal;
