import { memo } from 'react';
import { Box } from '@mui/material';
import { UserBot } from 'app/components/Bots/StrategyBuilder/types';
import {
  HoldingTypeData,
  IntervalsData,
  OrderTypeData,
  PositioningSizingData,
  RiskTypeData,
  StrategyBuilderTypes,
} from 'app/components/Bots/StrategyBuilder/util';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import InfoList from './InfoList';
import StrategySymbols from './StrategySymbols';
import PositionDirectionConditions from './PositionDirectionConditions';
import OptionalConditions from './OptionalConditions';
import Editor from '@monaco-editor/react';
import useGetOptionSymbols from 'services/Bots/useGetOptionSymbols';
import { equityJsonData } from '../StrategyBuilder/OptionStrategyBuilderContent';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { DropdownFieldValue } from 'app/components/Builder/OptionsStrategy/types';

const StrategyDetailContent = ({
  userBotDetails,
  botBuilderType,
}: {
  userBotDetails: UserBot;
  botBuilderType?: string;
}) => {
  const strategy = userBotDetails.sb_strategy;
  const strategyType = strategy?.type || '';
  const isOptionBotBuilderStockSymbols = useIsFeatureFlag([
    FEATURES.IS_OPTION_BOTBUILDER_STOCK_SYMBOLS,
  ]);
  const isShowUSAStockSymbols = useIsFeatureFlag([
    FEATURES.IS_SHOW_US_STOCK_SYMBOLS,
  ]);
  const isShowOptionSymbolFromBackend = useIsFeatureFlag([
    FEATURES.IS_SHOW_OPTION_SYMBOL_FROM_BACKEND,
  ]);
  const parameter =
    botBuilderType == 'option'
      ? isShowUSAStockSymbols
        ? 'USA_optionBuilder'
        : 'ALL_STOCKS_FNO'
      : botBuilderType?.toUpperCase() || 'ALL_STOCKS_FNO';
  const { data } = useGetOptionSymbols(
    parameter,
    isOptionBotBuilderStockSymbols,
    isShowUSAStockSymbols,
    isShowOptionSymbolFromBackend,
  );
  const modifiedData = data
    ? (data.data[parameter].Stocks.map(item => ({
        key: item.key || item.value,
        label: item.value,
        tooltip: '',
      })) as DropdownFieldValue[])
    : [];
  equityJsonData.value = modifiedData;
  if (
    !(strategyType in StrategyBuilderTypes) ||
    Object.keys(strategy!).length === 0
  )
    return <></>;

  const symbolInfoListItem = [
    {
      key: <FormattedMessage id="user_bot_startegy.chart_type" />,
      value: strategy!.chart,
    },
    {
      key: <FormattedMessage id="user_bot_startegy.interval" />,
      value: IntervalsData.filter(x => x.value === strategy!.resolution)[0]
        ?.label,
    },
  ];

  if (strategyType !== 'equity') symbolInfoListItem.splice(0, 1);

  return (
    <>
      {strategyType !== 'code' && (
        <>
          <Box>
            <InfoList
              title={<FormattedMessage id="user_bot_startegy.symbols" />}
              items={symbolInfoListItem}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StrategySymbols symbols={strategy!.universe} />
          </Box>
        </>
      )}
      <Box sx={{ mb: 3 }}>
        {!!(strategyType === 'equity') && (
          <PositionDirectionConditions
            direction={strategy!.direction}
            longEntryconditions={strategy!.long_entry.conditions}
            longExitconditions={strategy!.long_exit.conditions}
            shortEntryconditions={strategy!.short_entry.conditions}
            shortExitconditions={strategy!.short_exit.conditions}
          />
        )}
        {strategyType === 'option' && (
          <OptionalConditions
            strategy={strategy!}
            botBuilderType={botBuilderType}
          />
        )}
      </Box>
      <Box className="p-main-spacing" sx={{ mb: 3 }}>
        {strategyType === 'code' && (
          <Editor
            height={'70vh  '}
            defaultLanguage="python"
            theme={'vs-dark'}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={userBotDetails.sb_strategy?.code}
            options={{
              fontFamily: 'Helvetica, Tahoma, Arial, monospace',
              lineHeight: 1.6,
              lineNumbers: 'on',
              fontSize: 16,
              scrollBeyondLastLine: false,
              readOnly: true,
              cursorStyle: 'line',
              renderLineHighlight: 'gutter',
              hideCursorInOverviewRuler: true,
              overviewRulerBorder: false,
              wordWrap: 'on',
              wrappingIndent: 'indent',
              cursorBlinking: 'blink',
              contextmenu: true,
              mouseWheelZoom: true,
              automaticLayout: true,
              glyphMargin: true,
              folding: true,
              showUnused: true,
              renderWhitespace: 'none',
              scrollbar: {
                vertical: 'auto',
                horizontal: 'auto',
                useShadows: true,
                verticalHasArrows: true,
                horizontalHasArrows: true,
                verticalScrollbarSize: 12,
                horizontalScrollbarSize: 12,
                arrowSize: 16,
              },
              overviewRulerLanes: 2,
              fixedOverflowWidgets: true,
            }}
          />
        )}
      </Box>
      {strategyType === 'equity' && (
        <Box sx={{ mb: 3 }}>
          <InfoList
            title={
              <FormattedMessage id="user_bot_startegy.form.target_and_stoploss" />
            }
            items={[
              {
                key: <FormattedMessage id="user_bot_startegy.type" />,
                value: RiskTypeData.filter(
                  x => x.value === strategy!.risk_type,
                )[0]?.label,
              },
              {
                key: <FormattedMessage id="user_bot_startegy.form.target" />,
                value: strategy!.target_per,
              },
              {
                key: <FormattedMessage id="user_bot_startegy.form.stoploss" />,
                value: strategy!.stop_loss_per,
              },
              // {
              //   key: <FormattedMessage id="user_bot_startegy.form.trail_stoploss" />,
              //   value: strategy!.trailing_sl_enabled
              //     ? `Yes | ${strategy!.trailling_sl}`
              //     : 'No',
              // },
            ]}
          />
        </Box>
      )}
      {strategyType === 'equity' && (
        <Box sx={{ mb: 3 }}>
          <InfoList
            title={<FormattedMessage id="user_bot_startegy.portfolio" />}
            items={[
              {
                key: (
                  <FormattedMessage id="user_bot_startegy.positioning_sizing" />
                ),
                value: PositioningSizingData.filter(
                  x => x.value === strategy!.order_sizing.type,
                )[0]?.label,
              },
              {
                key: (
                  <FormattedMessage id="user_bot_startegy.form.position_value" />
                ),
                value: strategy!.order_sizing.value,
              },
              // {
              //   key: <FormattedMessage id="user_bot_startegy.max_risk_amount" />,
              //   value: strategy!.risk_percentage,
              // },
              {
                key: (
                  <FormattedMessage id="user_bot_startegy.form.order_type" />
                ),
                value: OrderTypeData.filter(
                  x => x.value === strategy!.order_type,
                )[0]?.label,
              },
              {
                key: (
                  <FormattedMessage id="user_bot_startegy.form.holding_type" />
                ),
                value: HoldingTypeData.filter(
                  x => x.value === strategy!.holding_type,
                )[0]?.label,
              },
            ]}
          />
        </Box>
      )}
    </>
  );
};
export default memo(StrategyDetailContent);
