import PageHeader from 'app/design/speedBot/PageHeader';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { title } from 'process';
import { TWebHookSignal } from 'types/ApiServicesTypes';
import InfoListSignal from './InfoListSignal';
import { commandColors } from 'app/design/speedBot/ComponentItem/WebHookSignals/utils';
interface IWebHookSignalDetailModalProps {
  open: boolean;
  handleClose: () => void;
  data?: TWebHookSignal;
}
const WebhookSignalDetailModal = ({
  open,
  handleClose,
  data,
}: IWebHookSignalDetailModalProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      <MuiFullScreenModal
        open={open}
        handleClose={handleClose}
        isNoBackButton={true}
        body={
          <>
            {data && (
              <PageHeader
                variant="withChipWithSubtitleWithBack"
                title={data ? data.symbol : ''}
                subtitle={data ? '' : ''}
                chipProps={{
                  label: data?.command,
                  color: commandColors[data?.command] || 'purplelight',
                  variant: 'filled',
                }}
                {...(isMdUp && { hideBackButton: true })}
                buttonText={title}
                buttonHandleClick={() => handleClose()}
              />
            )}
            {data && <InfoListSignal data={data} />}
          </>
        }
      />
    </>
  );
};

export default WebhookSignalDetailModal;
