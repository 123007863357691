import { Box, Typography } from '@mui/material';
import {
  BreadcrumbData,
  BreadcrumbInfo,
  GeneratorPages,
  PAGES,
} from 'app/components/Common/Breadcrumb/types';
import {
  TableColumns,
  TableRow,
  TableRowCellsData,
} from 'app/components/Common/CommonTable/type';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiChip from 'app/design/uiComponents/MuiChip';
import i18next from 'i18next';
import React from 'react';
import { TWebHookBot, TWebHookSignal } from 'types/ApiServicesTypes';
import { ChipColor } from 'types/Generic';
import { getDate, getTime } from 'utils/GenericFunctions';

export const commandColors: Record<string, ChipColor> = {
  buy: 'purplelight',
  sell: 'warninglight',
  long: 'info',
  closeall: 'warning',
  longlimit: 'infolight',
  cancellong: 'success',
  closelong: 'success',
  newsltplong: 'secondary',
  newsltpshort: 'error',
  newsltplongshort: 'primary',
};
export const createWebHookBotTableRows = (
  bots: TWebHookBot[],
  breadcrumbPush: {
    push: <T>(
      link: string,
      page: PAGES,
      backLink: string,
      label: string,
      data?: T,
    ) => void;
    clickBreadcrumb: (removeFromIndex: number, link: string) => void;
    getBreadcrumpPath: (
      page: GeneratorPages,
      data: BreadcrumbData,
    ) => BreadcrumbInfo[];
    resetBreadcrumpPaths: () => void;
    goBackPathPage: (link: string) => void;
    isPageUrlExist: (link: string) => boolean;
    isPageExist: (page: PAGES) => boolean;
  },

  handleViewBotClick: (bot: TWebHookBot) => void,
  handleActionClick: (status: string, sub_id: number) => void,
): { rows: TableRow<TWebHookBot>[]; columns: TableColumns[] } => {
  const rows: TableRow<TWebHookBot>[] = [];
  let rowCellsData: TableRowCellsData[] = [];

  const columns: TableColumns[] = [
    { text: i18next.t('webhook_signals.name'), key: 'name', align: 'left' },
    {
      text: i18next.t('webhook_signals.speedbot_id'),
      align: 'right',
      key: 'sub_id',
    },
    {
      text: i18next.t('webhook_signals.broker'),
      align: 'center',
      key: 'broker_name',
    },
    {
      text: i18next.t('webhook_signals.trading_account_no'),
      align: 'center',
      key: 'client_id',
    },
    {
      text: i18next.t('webhook_signals.status'),
      align: 'center',
      key: 'status',
    },
    {
      text: '     ',
      align: 'left',
    },
    {
      text: ' ',
      align: 'left',
    },
    {
      text: ' ',
      align: 'left',
    },
  ];

  bots.map(item => {
    rowCellsData = [];
    rows.push({
      rowCellsData: [
        {
          content: (
            <>
              <Typography
                variant="body1"
                sx={{ pb: 0.5, display: 'flex', alignItems: 'center' }}
              >
                {item.name}{' '}
              </Typography>
            </>
          ),
          fontWeight: 500,
          colSpan: 1,
        },
      ],
      rowData: item,
      noBorder: true,
      pb: 1,
      handleClick: () => {
        breadcrumbPush.push(
          `/signal/details/${item.sub_id}`,
          PAGES.SIGNALS,
          `/signal`,
          'Signals',
          [item],
        );
      },
      // link: `/portfolio/${ptype}/${item.id}`,
    });

    rowCellsData = [
      {
        content: '',
        align: 'left',
      },
      {
        content: item.sub_id,
        align: 'right',
      },
      {
        content: item.broker_name,
        align: 'center',
      },
      {
        content: item.client_id,
        align: 'center',
      },
      {
        content: (
          <MuiChip
            variant="filled"
            color={item.status === 'Active' ? 'successlight' : 'errorlight'}
            label={item.status === 'Active' ? 'Connected' : 'Disconnected'}
          />
          //   <Typography
          //     variant="body2"
          //     sx={{
          //       color: item.status === 'Active' ? 'success.main' : 'error.main',
          //     }}
          //   >
          //     {item.status === 'Active' ? 'Connected' : 'Disconnected'}
          //   </Typography>
        ),
        align: 'center',
      },
      {
        content: (
          <Box
            className="hover-visible"
            sx={{
              position: 'absolute',
              right: 0,
              bottom: 17,
              display: 'flex',
              alignItems: 'center',
              columnGap: 1,
            }}
          >
            <MuiButton
              className="hover-visible"
              variant="outlined"
              color={item.status === 'Active' ? 'error' : 'success'}
              onClick={(e: React.MouseEvent<MouseEvent>) => {
                e.stopPropagation();
                handleActionClick(
                  item.status === 'Active' ? 'Paused' : 'Active',
                  item.sub_id,
                );
              }}
            >
              {item.status === 'Active' ? 'Stop' : 'Start'}
            </MuiButton>
          </Box>
        ),
        align: 'left',
      },
      {
        content: (
          <Box
            className="hover-visible"
            sx={{
              position: 'absolute',
              left: 15,
              bottom: 17,
              display: 'flex',
              alignItems: 'center',
              columnGap: 1,
            }}
          >
            <MuiButton
              variant="contained"
              color="secondary"
              onClick={(e: React.MouseEvent<MouseEvent>) => {
                handleViewBotClick(item);
                e.stopPropagation();
              }}
            >
              {/* <TrackChanges sx={{ fontSize: 24 }} />
               */}
              Setup Info
            </MuiButton>
          </Box>
        ),
        align: 'left',
      },
    ];

    rows.push({
      pt: 0,
      rowCellsData,
      rowData: item,
      handleClick: () => {
        breadcrumbPush.push(
          `/signal/details/${item.sub_id}`,
          PAGES.SIGNALS,
          `/signal`,
          'Signals',
          [item],
        );
      },
      // link: `/portfolio/${ptype}/${item.id}`,
      visibleOnHover: true,
    });
  });

  return { rows, columns };
};

export const createWebHookSignalTableRows = (
  signals: TWebHookSignal[],
  handleClick: (TWebHookSignal) => void,
  // breadcrumbPush: BreadcrumbPush,
): { rows: TableRow<TWebHookSignal>[]; columns: TableColumns[] } => {
  const rows: TableRow<TWebHookSignal>[] = [];
  let rowCellsData: TableRowCellsData[] = [];
  const commandMap = {
    buy: 'Buy',
    sell: 'Sell',
    long: 'Long',
    closeall: 'Close All',
    longlimit: 'Long Limit',
    cancellong: 'Cancel Long',
    closelong: 'Cancel Long',
    newsltplong: 'New SL TP Long',
    newsltpshort: 'New SL TP Short',
    newsltplongshort: 'New SL TP Long Short',
    shortlimit: 'Short Limit',
    cancelall: 'Cancel All',
    buystop: 'Buy Stop',
    short: 'Short',
  };
  const columns: TableColumns[] = [
    { text: 'Time Received', align: 'left' },
    {
      text: 'Time Finished',
      align: 'left',
      key: 'sub_id',
    },
    {
      text: 'Symbols',
      align: 'center',
      key: 'sub_id',
    },
    {
      text: 'Command',
      align: 'left',
      key: 'broker_name',
    },

    {
      text: 'Risk',
      align: 'right',
      key: 'status',
    },
    {
      text: 'Take Profit',
      align: 'right',
      key: 'status',
    },
    {
      text: 'Stop Loss',
      align: 'right',
      key: 'status',
    },
  ];

  signals.map(item => {
    rowCellsData = [];

    rowCellsData = [
      {
        content: (
          <>
            {getTime(item.time_received)}
            <br />
            {getDate(item.time_received, false)}
          </>
        ),
      },
      {
        content: (
          <>
            {getTime(item.time_finish)}
            <br />
            {getDate(item.time_finish, false)}
          </>
        ),
      },
      {
        content: item.symbol,
        align: 'center',
      },
      {
        // content: commandMap[item.command] as string,
        content: (
          <MuiChip
            label={
              commandMap[item.command] !== undefined
                ? (commandMap[item.command] as string)
                : item.command
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            color={commandColors[item.command]}
            // sx={{
            //   bgcolor: `${commandColors[item.command] || 'default'}.main`, // Fall back to default if no match
            //   color: 'white', // Ensure contrast
            // }}
          />
        ),
        align: 'left',
      },

      {
        content: item.risk,
        align: 'right',
      },
      {
        content: item.tp,
        align: 'right',
      },
      {
        content: item.sl,
        align: 'right',
      },
    ];
    rows.push({
      pt: 0,
      rowCellsData,
      rowData: item,
      handleClick: () => {
        handleClick(item);
      },
      //   handleClick: () => {
      //     breadcrumbPush(
      //       `/signal/details/${item.sub_id}`,
      //       PAGES.SIGNALS,
      //       `/signal`,
      //       i18next.t('f.page_title.portfolio'),
      //     );
      //   },
      //   // link: `/portfolio/${ptype}/${item.id}`,
    });
  });

  return { rows, columns };
};
