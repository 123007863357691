import { useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import EditIcon from '@mui/icons-material/Edit';
import StrategyDetailContent from 'app/components/Bots/Strategy/StrategyDetailContent';
import InfoModal from 'app/components/Common/InfoModal';
import { InfoType } from 'types/ComponentTypes';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

// Service
import useGetUserBot from 'services/Bots/useGetUserBot';
import { StrategyBuilderTypes } from 'app/components/Bots/StrategyBuilder/util';
import { getQueryParam } from 'utils/GenericFunctions';

const infoModalData: InfoType[] = [
  {
    title: '',
    desc: <FormattedMessage id="user_bot_startegy.edit_your_bot_strategy" />,
  },
];

export function UserBotStrategyDetailPage() {
  const breadcrumb = useBreadcrumbManager();
  const history = useHistory();
  const { id, sid } = useParams<{ id: string; sid: string }>();
  const { t } = useTranslation();
  const userBotId = parseInt(id);

  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const handleInfoOpen = useCallback(() => {
    setOpenInfo(true);
  }, [setOpenInfo]);

  const handleInfoClose = useCallback(() => {
    setOpenInfo(false);
  }, [setOpenInfo]);

  const versionId = getQueryParam('versionid');
  const { data: userBotDetails, isLoading } = useGetUserBot(
    userBotId,
    versionId,
  );
  const strategyType = userBotDetails?.sb_strategy?.type || '';
  const isShowEdit = strategyType in StrategyBuilderTypes;
  const exchangeMapping: Record<string, string> = {
    nse: 'option',
    bse: 'option',
    usa: 'option',
    crypto_india: 'crypto_india',
    crypto_global: 'crypto_global',
    mcx: 'mcx',
  };
  const botBuilderType: string | undefined =
    userBotDetails && Object.keys(userBotDetails).length > 0
      ? exchangeMapping[userBotDetails.exchange?.toLowerCase() as string]
      : 'option';
  return (
    <>
      <Helmet>
        <title>User bot backtesting</title>
        <meta name="description" content="User bot backtesting" />
      </Helmet>
      <CircularLoader open={isLoading} />
      {userBotDetails && (
        <>
          <PageHeader
            variant="withIconWithBack"
            buttonText={userBotDetails ? userBotDetails.name : ''}
            title={<FormattedMessage id="user_bot_startegy.page_title" />}
            buttonHandleClick={() => history.goBack()}
            iconProps={{
              icon:
                isShowEdit &&
                userBotDetails.is_own_bot &&
                userBotDetails.sb_strategy?.type ? (
                  <EditIcon fontSize="small" />
                ) : (
                  <></>
                ),
              ...(userBotDetails.is_own_bot &&
                userBotDetails.is_editable && {
                  handleClick: () =>
                    breadcrumb.push(
                      `/bot-builder/${id}/${sid ? sid : '0'}/${
                        userBotDetails.strategy_id
                      }`,
                      PAGES.BOT_STRATEGY_DETAIL,
                      `/bot/${id}/strategy${sid ? `/${sid}` : ''}`,
                      t('buttons.strategy'),
                    ),
                }),
              ...(userBotDetails.is_own_bot &&
                !userBotDetails.is_editable && {
                  handleClick: handleInfoOpen,
                }),
            }}
            breadcrumb={
              <Breadcrumbs
                page={PAGES.BOT_STRATEGY_DETAIL}
                data={{
                  subId: sid,
                  botId: userBotDetails ? userBotDetails.strategy_id : 0,
                  botName: userBotDetails ? userBotDetails.name : '',
                }}
              />
            }
          />
          <StrategyDetailContent
            userBotDetails={userBotDetails}
            botBuilderType={botBuilderType}
          />
        </>
      )}

      <InfoModal
        data={infoModalData}
        open={openInfo}
        handleClose={handleInfoClose}
        closeBtn={true}
      />
    </>
  );
}
