import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from '@mui/material';
import React, { useState, useRef, useMemo, useEffect, memo } from 'react';
import {
  DAY_WISE_STOPLOSS,
  DAY_WISE_TARGETSTOPLOSS,
  FieldParam,
  FieldParamValue,
  LOCK_PROFIT,
  OptionDayWiseParam,
} from './types';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  OptionStrategyLockProfitFieldsGroupWise,
  OptionStrategyStopLossFieldsGroupWise,
  OptionStrategyTargetProfitFieldsGroupWise,
  OptionStrategyTrailingStopLossFieldsGroupWise,
} from './FieldsData';
import { v4 } from 'uuid';
import { generateOptionValues } from './Utils/utils';

interface Props {
  dayWiseSSL: OptionDayWiseParam;
  dayWiseIndex: number;
  onChange: (index: number, dayWiseSSL: OptionDayWiseParam) => void;
  onDelete: (index: number) => void;
  PlValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  propKey: string;
}

const OptionDayWiseSSL = (props: Props) => {
  const { dayWiseSSL, dayWiseIndex, isReadOnly, onDelete } = props;
  const validationDataRef = useRef({});

  const [showMenu, setShowMenu] = useState<null | HTMLElement>(null);
  const open = Boolean(showMenu);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setShowMenu(null);
  };

  useEffect(() => {
    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      props.PlValidation(
        `dayWise-${props.propKey}-${props.dayWiseIndex}`,
        !Object.values(validationDataRef.current).includes(false),
      );
    } else {
      props.PlValidation(
        `dayWise-${props.propKey}-${props.dayWiseIndex}`,
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationDataRef, props.PlValidation, props.dayWiseIndex]);

  const options = useMemo(() => {
    return [
      {
        text: 'Delete',
        icon: <DeleteIcon fontSize="small" />,
        handler: (index: number) => onDelete(index),
      },
    ];
  }, [onDelete]);

  const sendValidation = (key: string, isValid: boolean) => {
    validationDataRef.current = {
      ...validationDataRef.current,
      [key]: isValid,
    };
  };

  const onChange = (key: string, value: Record<string, FieldParamValue>) => {
    dayWiseSSL[key] = value;

    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      props.PlValidation(
        `dayWise-${props.propKey}-${dayWiseIndex}`,
        !Object.values(validationDataRef.current).includes(false),
      );
    } else {
      props.PlValidation(`dayWise-${props.propKey}-${dayWiseIndex}`, true);
    }

    props.onChange(dayWiseIndex, dayWiseSSL);
  };

  const renderOptionSetFields = (groupwiseFields: FieldParam[]) => {
    const resultData = groupwiseFields.reduce(
      (
        returnData: { currData: JSX.Element[]; finalResult: JSX.Element[] },
        field: FieldParam,
        index: number,
        fieldArray: FieldParam[],
      ) => {
        field = generateOptionValues(field, {}, {}, dayWiseSSL, {});
        // field = _.cloneDeep(field);
        if (!field.hide) {
          returnData.currData.push(
            <field.renderer
              param={field as Omit<FieldParam, 'renderer' | 'type'>}
              onChange={onChange}
              key={`dayWise-${props.propKey}-${dayWiseIndex}-${field.parentKey}`}
              selected={
                dayWiseSSL[field.parentKey] as Record<string, FieldParamValue>
              }
              sendValidation={sendValidation}
              isReadOnly={isReadOnly}
            />,
          );
        }
        if (
          (fieldArray[index + 1] &&
            fieldArray[index + 1].group !== field.group) ||
          index === fieldArray.length - 1
        ) {
          returnData.finalResult.push(
            <Grid
              container
              columnSpacing={2}
              spacing={2}
              key={`dayWise-${props.propKey}-grid-${v4()}`}
            >
              {returnData.currData}
            </Grid>,
          );
          returnData.currData = [];
        }
        return returnData;
      },
      { currData: [], finalResult: [] },
    );
    return resultData.finalResult;
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {isReadOnly ? (
          <></>
        ) : (
          <Box>
            <IconButton
              style={{
                width: '24px',
                minWidth: '24px',
                height: '24px',
                fontSize: '16px',
                border: 'none',
                position: 'absolute',
                right: '0',
                // right: '24px',
                top: '16px',
              }}
              disabled={isReadOnly}
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Paper sx={{ width: 320, maxWidth: '100%' }}>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={showMenu}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    width: '20ch',
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                {options.map(option => {
                  return (
                    <MenuItem
                      onClick={() => {
                        option.handler(dayWiseIndex);
                        handleMenuClose();
                      }}
                      key={option.text}
                      // disabled={disabled}
                    >
                      <ListItemIcon>{option.icon}</ListItemIcon>
                      <ListItemText>{option.text}</ListItemText>
                    </MenuItem>
                  );
                })}
              </Menu>
            </Paper>
          </Box>
        )}
        {renderOptionSetFields(
          props.propKey === DAY_WISE_TARGETSTOPLOSS
            ? OptionStrategyTrailingStopLossFieldsGroupWise
            : props.propKey === DAY_WISE_STOPLOSS
            ? OptionStrategyStopLossFieldsGroupWise
            : props.propKey == LOCK_PROFIT
            ? OptionStrategyLockProfitFieldsGroupWise
            : OptionStrategyTargetProfitFieldsGroupWise,
        )}
      </Box>
    </>
  );
};

export default memo(OptionDayWiseSSL);
