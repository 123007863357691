import { Helmet } from 'react-helmet-async';
import PageHeader from 'app/design/speedBot/PageHeader';
import EditBrokerForm from 'app/components/Menu/EditBrokerForm';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import MtVpsTerminal from 'app/components/Menu/MtVpsTerminal';
import useGetBrokerById from 'services/Menu/useGetBrokerById';
import { useParams } from 'react-router-dom';

export function EditBrokerPage() {
  const isMdUp = useGetMediaQueryUp('md');
  const { id } = useParams<{ id: string }>();
  const { data: brokerData, isLoading: brokerIsLoading } = useGetBrokerById(
    parseInt(id),
  );
  return (
    <>
      <Helmet>
        <title>Edit Broker</title>
        <meta name="description" content="Edit Broker Page" />
      </Helmet>

      <PageHeader
        variant="inactive"
        buttonText={<FormattedMessage id="menu.page_title.brokers" />}
        title={<FormattedMessage id="menu.page_title.edit_broker" />}
        buttonHandleClick="/broker"
        breadcrumb={<Breadcrumbs page={PAGES.EDIT_BROKER} data={{}} />}
        rightElement={
          isMdUp ? <MtVpsTerminal brokerData={brokerData} /> : <></>
        }
      />
      {!isMdUp && <MtVpsTerminal brokerData={brokerData} />}

      <EditBrokerForm
        brokerData={brokerData}
        brokerIsLoading={brokerIsLoading}
      />
    </>
  );
}
