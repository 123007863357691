import { useState, memo, useMemo } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Link,
  Grid,
  Alert,
  Typography,
  IconButton,
  OutlinedInput,
  Autocomplete,
  TextField,
} from '@mui/material';
import { Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { ConcatSchema } from './utils';
import MuiButton from 'app/design/uiComponents/MuiButton';
import FormControlItems from './FormControlItems';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import { v4 as uuidv4 } from 'uuid';

// Service
import { BrokerById, GetBrokerFormResult } from 'types/ApiServicesTypes';
import { getFullDomain, isBlankHtmlString } from 'utils/GenericFunctions';
import BrokerItem from './BrokerItem';
import { ContentCopy, TaskAlt } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const DynamicForm = ({
  formData,
  submitButtoneText,
  cancelButtonLink,
  brokerData,
  isLoading,
  handleFormSubmit,
  handleFormDelete,
  handleFormVerify,
  handleBrokerLogout,
  isAngelIncluded,
}: {
  formData: GetBrokerFormResult;
  submitButtoneText: string;
  cancelButtonLink: string;
  brokerData?: BrokerById | undefined;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleFormSubmit: (values: any) => void;
  handleFormDelete?: () => void;
  handleFormVerify?: () => void;
  handleBrokerLogout?: () => void;
  isAngelIncluded?: boolean;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();
  const isMdUp = useGetMediaQueryUp('md');
  const [showGreenTick, setShowGreenTick] = useState(false);
  let initBroker = 0;
  const isDisableAngelBroker = useIsFeatureFlag([
    FEATURES.IS_DISABLE_ANGEL_BROKER,
  ]);
  const [inputValue, setInputValue] = useState('');
  if (brokerData && formData && formData.brokers) {
    const objInitBroker = formData.brokers.filter(
      x => x.id === brokerData.brokerId,
    )[0];
    if (objInitBroker) initBroker = objInitBroker.id;
  }

  const [selectedBroker, setSelectedBroker] = useState<number | null>(
    initBroker || null,
  );
  const selectedBrokerData = useMemo(() => {
    if (selectedBroker) {
      const broker = formData.brokers.filter(x => x.id === selectedBroker)[0];
      return {
        ...broker,
        fields: [
          {
            id: 11,
            label: 'Name (Optional)',
            key: 'name',
            type: 'input',
            defaultValue: initBroker ? '' : broker.name,
            validationRules: [],
          },
          ...broker.fields,
        ],
      };
    }
    return null;
  }, [formData, selectedBroker, initBroker]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validationSchema: any = useMemo(() => {
    let validationSchemaData = Yup.object().shape({});
    if (selectedBrokerData && selectedBrokerData.fields) {
      selectedBrokerData.fields.forEach(field => {
        if (field.validationRules) {
          validationSchemaData = validationSchemaData.concat(
            Yup.object({
              [field.key]: ConcatSchema(field),
            }),
          );
        }
      });
    }

    return validationSchemaData;
  }, [selectedBrokerData]);

  const initialValues: FormikValues = useMemo(() => {
    const initVal: FormikValues = {
      broker: selectedBroker || '',
    };
    if (selectedBrokerData && selectedBrokerData.fields) {
      selectedBrokerData.fields.forEach(field => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        initVal[field.key] =
          brokerData && brokerData[field.key]
            ? brokerData[field.key]
            : field.defaultValue || '';
      });
    }
    initVal.uuid = brokerData ? brokerData.uuid : uuidv4();

    return initVal;
  }, [selectedBroker, selectedBrokerData, brokerData]);

  const copyToClipboard = (values: FormikValues) => {
    void navigator.clipboard.writeText(
      `${curDomain}/broker/auth/${values.uuid}`,
    );
    setShowGreenTick(true);
    setTimeout(() => {
      setShowGreenTick(false);
    }, 700);
  };
  const isAutoBrokerDropdown = useIsFeatureFlag([
    FEATURES.IS_SHOW_AUTO_BROKER_DROPDOWN,
  ]);
  const curDomain = getFullDomain();
  return (
    <Box sx={{ height: '100%' }}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        validationSchema={validationSchema}
        onSubmit={values => {
          if (selectedBrokerData) handleFormSubmit(values);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          dirty,
          isValid,
          errors,
          setFieldValue,
        }) => {
          let isChanged = false;
          if (brokerData && selectedBrokerData && selectedBrokerData.fields) {
            selectedBrokerData.fields.forEach(field => {
              if (values[field.key] != brokerData[field.key]) {
                isChanged = true;
                return;
              }
            });
          }

          return (
            <form noValidate onSubmit={handleSubmit} style={{ height: '100%' }}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box className="m-main-spacing" sx={{ mt: 2, flex: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={6}>
                      {isAutoBrokerDropdown ? (
                        <FormControl
                          fullWidth
                          sx={{
                            mb:
                              selectedBrokerData &&
                              !isBlankHtmlString(
                                selectedBrokerData.broker_notes,
                              )
                                ? 0
                                : 2,
                          }}
                        >
                          {/* <InputLabel id="lab el-broker">Broker</InputLabel> */}
                          <Autocomplete
                            options={formData.brokers.filter(broker =>
                              isAngelIncluded
                                ? broker
                                : isDisableAngelBroker
                                ? broker.key !== 'angel_broking'
                                : broker,
                            )}
                            getOptionLabel={option => option.name || ''}
                            filterOptions={options => {
                              if (inputValue.length < 3) return [];
                              return options.filter(option =>
                                option.name
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase()),
                              );
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            value={
                              formData.brokers.find(
                                broker => broker.id === values.broker,
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              setSelectedBroker(newValue ? newValue.id : null);
                              setFieldValue(
                                'broker',
                                newValue ? newValue.id : '',
                              );
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                <BrokerItem {...option} />
                              </Box>
                            )}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Broker"
                                variant="outlined"
                                helperText={
                                  inputValue.length < 3
                                    ? 'Enter at least 3 characters to see results'
                                    : ''
                                }
                              />
                            )}
                            disabled={brokerData ? true : false}
                            noOptionsText={
                              inputValue.length < 3
                                ? 'Enter at least 3 characters to see results'
                                : 'Oops! no results found'
                            }
                          />

                          {selectedBrokerData && selectedBrokerData.help_link && (
                            <Link
                              href={selectedBrokerData.help_link}
                              target="_blank"
                              sx={{ mt: 1 }}
                            >
                              <FormattedMessage id="how_to_generate_required_keys" />
                            </Link>
                          )}
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          sx={{
                            mb:
                              selectedBrokerData &&
                              !isBlankHtmlString(
                                selectedBrokerData.broker_notes,
                              )
                                ? 0
                                : 2,
                          }}
                        >
                          <InputLabel id="label-broker">Broker</InputLabel>
                          <Select
                            labelId="label-broker"
                            name="broker"
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            value={values.broker}
                            label={t('common.broker')}
                            onChange={e => {
                              setSelectedBroker(e.target.value as number);
                              setFieldValue('broker', e.target.value);
                            }}
                            disabled={brokerData ? true : false}
                          >
                            {formData.brokers
                              .filter(item =>
                                isAngelIncluded
                                  ? item
                                  : isDisableAngelBroker
                                  ? item.key !== 'angel_broking'
                                  : item,
                              )
                              .map(broker => (
                                <MenuItem key={broker.id} value={broker.id}>
                                  <BrokerItem {...broker} />
                                </MenuItem>
                              ))}
                          </Select>
                          {selectedBrokerData && selectedBrokerData.help_link && (
                            <Link
                              href={selectedBrokerData.help_link}
                              target="_blank"
                              sx={{ mt: 1 }}
                            >
                              <FormattedMessage id="how_to_generate_required_keys" />
                            </Link>
                          )}
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}></Grid>

                    {selectedBrokerData &&
                      !isBlankHtmlString(selectedBrokerData.broker_notes) && (
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            variant="body2"
                            sx={{ '& p': { m: 0 }, mb: 4 }}
                            dangerouslySetInnerHTML={{
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              __html: selectedBrokerData.broker_notes,
                            }}
                          ></Typography>
                        </Grid>
                      )}
                    {selectedBrokerData && selectedBrokerData.fields && (
                      <>
                        <FormControlItems
                          fields={selectedBrokerData.fields}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values}
                          touched={touched}
                          errors={errors}
                        />
                      </>
                    )}

                    {selectedBrokerData &&
                      selectedBrokerData.is_auth &&
                      selectedBrokerData.show_redirect_url && (
                        <Grid item xs={12} md={12}>
                          {/* <Box> */}
                          <Alert
                            severity="warning"
                            sx={{ mb: 2, '& .MuiAlert-message': { flex: 1 } }}
                          >
                            <Typography variant="body2">Note</Typography>
                            <Typography variant="body2" fontWeight={400}>
                              Please set below redirect url to your
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                component={'span'}
                                mx={0.5}
                              >
                                {selectedBrokerData.name}
                              </Typography>
                              app
                            </Typography>
                            {/* <Typography variant="body2" fontWeight={400}>
                                {`${curDomain}/broker/auth/${values.uuid}`}
                              </Typography> */}
                            {/* <FormControl fullWidth> */}
                            <OutlinedInput
                              type="text"
                              name="redirect_url"
                              value={`${curDomain}/broker/auth/${values.uuid}`}
                              readOnly
                              fullWidth
                              sx={{
                                fontSize: '16px',
                                border: 'none',
                                outline: 'none',
                                whiteSpace: 'nowrap',
                                padding: '0px 10px',
                                mt: 1,
                                // textOverflow: 'ellipsis',
                              }}
                              endAdornment={
                                <IconButton
                                  aria-label="Copy"
                                  onClick={() => {
                                    copyToClipboard(values);
                                  }}
                                >
                                  {showGreenTick ? (
                                    <TaskAlt
                                      sx={{
                                        color: theme.palette.success.light,
                                      }}
                                    />
                                  ) : (
                                    <ContentCopy />
                                  )}
                                </IconButton>
                              }
                            />
                            {/* </FormControl> */}
                          </Alert>
                          {/* </Box> */}
                        </Grid>
                      )}
                  </Grid>
                </Box>
                <Box
                  className="m-main-spacing"
                  sx={{
                    mt: 3,
                    mb: 0,
                    textAlign: 'center',
                    display: { xs: 'block', md: 'flex' },
                    justifyContent: 'center',
                    flexDirection: 'row-reverse',
                  }}
                >
                  {brokerData && !isChanged && (
                    <MuiButton
                      variant="outlined"
                      color="error"
                      fullWidth={!isMdUp}
                      sx={{
                        mb: { xs: 2, md: 0 },
                      }}
                      onClick={() => handleFormDelete!()}
                      // sx={{ mt: 2 }}
                    >
                      <FormattedMessage id="buttons.remove" />
                    </MuiButton>
                  )}
                  {((brokerData && isChanged) || !brokerData) && (
                    <MuiButton
                      fullWidth={!isMdUp}
                      sx={{
                        mb: { xs: 2, md: 0 },
                      }}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={
                        Boolean(!isValid && dirty && touched) || isLoading
                      }
                    >
                      {submitButtoneText}
                    </MuiButton>
                  )}
                  {selectedBrokerData &&
                    selectedBrokerData.is_auth &&
                    // selectedBrokerData.show_redirect_url &&
                    brokerData &&
                    brokerData.isVerified === false &&
                    !isChanged && (
                      <MuiButton
                        fullWidth={!isMdUp}
                        sx={{
                          mb: { xs: 2, md: 0 },
                          mr: 2,
                        }}
                        variant="contained"
                        color="primary"
                        // type="submit"
                        disabled={Boolean(!isValid && touched) || isLoading}
                        onClick={() => handleFormVerify!()}
                      >
                        <FormattedMessage id="buttons.login" />
                      </MuiButton>
                    )}
                  {selectedBrokerData &&
                    selectedBrokerData.is_auth &&
                    // selectedBrokerData.show_redirect_url &&
                    brokerData &&
                    brokerData.isVerified === true && (
                      <MuiButton
                        fullWidth={!isMdUp}
                        sx={{
                          mb: { xs: 2, md: 0 },
                          mr: 2,
                        }}
                        variant="contained"
                        color="primary"
                        type="button"
                        disabled={Boolean(!isValid && touched) || isLoading}
                        onClick={() => handleBrokerLogout!()}
                      >
                        <FormattedMessage id="buttons.logout" />
                      </MuiButton>
                    )}
                  <MuiButton
                    fullWidth={!isMdUp}
                    sx={{
                      mr: { xs: 0, md: 2 },
                    }}
                    variant="contained"
                    color="secondary"
                    // component={RouterLink}
                    // to={cancelButtonLink}
                    onClick={() => breadcrumb.goBackPathPage(cancelButtonLink)}
                  >
                    <FormattedMessage id="buttons.cancel" />
                  </MuiButton>
                </Box>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
export default memo(DynamicForm);
