import { useState, useCallback, useEffect, useMemo, Fragment } from 'react';
// import _ from 'lodash';
import {
  FormControl,
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  InputAdornment,
  IconButton,
  TextField,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  FieldParam,
  DropdownFieldValue,
  SelectWithDoubleInputParam,
  FieldParamValue,
  ValidateState,
  ControlsType,
  OptionLegParam,
} from '../types';
import { cloneDeep } from 'lodash';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { generateValuesBeforeSend } from '../Utils/utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';

type Param = Omit<FieldParam, 'renderer' | 'type'>;
interface Props {
  param: Param;
  selected: Record<string, FieldParamValue>;
  onChange: (key: string, value: Record<string, FieldParamValue>) => void;
  sendValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  optionLeg?: OptionLegParam;
}

export const SelectWithDoubleInputRenderer = (props: Props) => {
  const { param, onChange, sendValidation, isReadOnly } = props;
  let selected = props.selected;
  const {
    name,
    controls,
    parentKey,
    myStyle,
    addClass,
    width,
    isAddButtonActive,
  } = param as SelectWithDoubleInputParam;
  const [isValidStatus, setIsValidStatus] = useState<ValidateState>({
    error: '',
    status: false,
  });
  const [userInput, setUserInput] = useState(selected);
  const [showAddCheckbox, setShowAddCheckbox] = useState<boolean>(
    props.selected.toggleStatus as boolean,
  );
  const globaltheme = useTheme();
  const validate = useCallback(
    (formData: Record<string, FieldParamValue>) => {
      if (Object.prototype.hasOwnProperty.call(formData, 'toggleStatus')) {
        delete formData.toggleStatus;
      }
      let validStatus = {
        status: false,
        error: '',
      };
      if (!selected.toggleStatus) {
        Object.keys(formData).forEach((item, index) => {
          if (
            !Object.values(formData).includes('none') &&
            (!formData[item] || (formData[item] && formData[item] === '0')) &&
            (controls[index]?.isRequired || false)
          ) {
            validStatus = {
              status: true,
              error: `Please enter valid ${
                controls[index].type === 'dropdown' ? 'dropdown' : 'input'
              } data`,
            };
          }
        });
      }
      return validStatus;
    },
    [controls, selected],
  );

  useEffect(() => {
    const validStatus = validate(cloneDeep(selected));
    setIsValidStatus(validStatus);
    sendValidation(parentKey, !validStatus.status);

    setShowAddCheckbox(
      isReadOnly ? !isReadOnly : (props.selected.toggleStatus as boolean),
    );
  }, [
    validate,
    parentKey,
    sendValidation,
    selected,
    props.selected.toggleStatus,
    isReadOnly,
  ]);

  const onChangeFieldType = (
    event: SelectChangeEvent<string>,
    control: ControlsType,
  ) => {
    if (event.target.value === 'Remove') {
      const checkboxField = controls.find(e => e.type === 'checkbox');
      if (
        checkboxField &&
        Object.prototype.hasOwnProperty.call(selected, checkboxField.key)
      ) {
        selected[checkboxField.key] = !selected[checkboxField.key];
      }
      setShowAddCheckbox(!showAddCheckbox);
      sendValidation(parentKey, true);
      Object.keys(selected).forEach(item => {
        const currentItem = controls.find(e => e.key === item);
        if (item === control.key) {
          selected[item] = control.defaultValue as string;
        } else {
          selected[item] = currentItem?.defaultValue
            ? currentItem.defaultValue
            : selected[item];
        }
      });
      selected.toggleStatus = true;
      onChange(parentKey, selected);
    } else {
      selected[control.key] = event.target.value;
      const validStatus = validate(cloneDeep(selected));
      sendValidation(parentKey, !validStatus.status);
      onChange(parentKey, selected);
    }
  };

  const theme = useMemo(() => {
    return createTheme({
      components: {
        MuiSelect: {
          styleOverrides: {
            icon: {
              color: globaltheme.palette.text.secondary,
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              display: `${isReadOnly ? 'none' : 'block'}`,
              color: globaltheme.palette.text.secondary,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            input: {
              '&.Mui-disabled': {
                color: globaltheme.palette.text.disabled,
                ' -webkit-text-fill-color': globaltheme.palette.text.disabled,
              },
              color: globaltheme.palette.text.primary,
            },
            root: {
              paddingRight: 0,
            },
            notchedOutline: {
              border: 'none',
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              top: '7px',
            },
            shrink: {
              top: '0px',
            },
          },
        },

        MuiMenuItem: {
          styleOverrides: {
            root: {
              '&.css-j4nee7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
                {
                  paddingRight: '0',
                },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: globaltheme.palette.common.white,
              color: globaltheme.palette.common.black,
            },
          },
        },
      },
    });
  }, [isReadOnly, globaltheme]);

  const onInputFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    control: ControlsType,
  ) => {
    selected[control.key] = event.target.value;
    const validStatus = validate(cloneDeep(selected));
    setIsValidStatus(validStatus);
    setUserInput(selected);
  };

  const onFocusOutHandler = () => {
    const validStatus = validate(cloneDeep(userInput));
    sendValidation(parentKey, !validStatus.status);
    onChange(parentKey, selected);
  };

  return (
    <>
      {isReadOnly && controls.some(e => selected[e.key] === 'none') ? null : (
        <Grid
          item
          className={addClass}
          style={
            width
              ? {
                  marginBottom: '24px',
                  alignSelf: 'center',
                  width: `${width}px`,
                }
              : { marginBottom: '24px', alignSelf: 'center' }
          }
        >
          <GlobalStyles
            styles={{
              [theme.breakpoints.down('md')]: myStyle?.small
                ? myStyle.small
                : myStyle?.root
                ? myStyle.root
                : myStyle,
              [theme.breakpoints.up('md')]: myStyle?.medium
                ? myStyle.medium
                : myStyle?.root
                ? myStyle.root
                : myStyle,
              [theme.breakpoints.up('lg')]: myStyle?.large
                ? myStyle.large
                : myStyle?.root
                ? myStyle.root
                : myStyle,
            }}
          />
          {(showAddCheckbox && isAddButtonActive) ||
          controls.some(e => selected[e.key] === 'none') ? (
            <CustomTooltip tooltipKey={param.uniqueKey}>
              {() => (
                <Button
                  variant="outlined"
                  startIcon={<AddCircleIcon />}
                  onClick={() => {
                    const checkboxField = controls.find(
                      e => e.type === 'checkbox',
                    );
                    if (
                      checkboxField &&
                      Object.prototype.hasOwnProperty.call(
                        selected,
                        checkboxField.key,
                      )
                    ) {
                      selected[checkboxField.key] =
                        !selected[checkboxField.key];
                    }
                    selected.toggleStatus = false;
                    setShowAddCheckbox(!showAddCheckbox);
                    const validStatus = validate(cloneDeep(userInput));
                    sendValidation(parentKey, !validStatus.status);
                    selected = generateValuesBeforeSend(param, selected);
                    onChange(parentKey, selected);
                  }}
                  className="remove-border"
                >
                  {name}
                </Button>
              )}
            </CustomTooltip>
          ) : (
            <FormControl
              variant="outlined"
              className={`select-with-double-input-box ${
                isValidStatus.status ? 'error' : ''
              } ${width ? 'full-width' : ''}`}
            >
              <ThemeProvider theme={theme}>
                {controls.map(control => (
                  <Fragment key={control.key}>
                    <InputLabel
                      id={control.key}
                      sx={{
                        backgroundColor:
                          globaltheme.component.mainLayoutContainer.main,
                        padding: '0 7px',
                        color: globaltheme.palette.text.secondary,
                      }}
                    >
                      {name}
                    </InputLabel>
                    {control.type === 'dropdown' ? (
                      <Select
                        onChange={(event: SelectChangeEvent<string>) => {
                          onChangeFieldType(event, control);
                        }}
                        name={control.key}
                        id={name}
                        className="select"
                        value={selected[control.key] as string}
                        style={
                          width
                            ? {
                                width: `${width}px`,
                              }
                            : {}
                        }
                        disabled={isReadOnly}
                        error={isValidStatus.status}
                      >
                        {control.values?.map((value: DropdownFieldValue) => (
                          <MenuItem
                            key={value.key}
                            value={value.key}
                            className="select-option"
                          >
                            <div>{value.label}</div>
                          </MenuItem>
                        ))}
                      </Select>
                    ) : // </Tooltip>
                    control.type === 'input' ? (
                      <TextField
                        type="number"
                        variant="outlined"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => onInputFieldChange(event, control)}
                        name={control.key}
                        onBlur={onFocusOutHandler}
                        value={selected[control.key] as string}
                        error={isValidStatus.status}
                        disabled={isReadOnly}
                        className="text-field"
                        fullWidth
                        sx={{
                          '& .css-10zqgek-MuiInputBase-root-MuiOutlinedInput-root':
                            { width: control.tooltip ? '150%' : '100%' },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {control.tooltip && (
                                <CustomTooltip tooltipKey={control.tooltip}>
                                  {({ isTooltipAvailable }) =>
                                    isTooltipAvailable ? (
                                      <IconButton>
                                        <InfoOutlinedIcon fontSize="small" />
                                      </IconButton>
                                    ) : (
                                      <></>
                                    )
                                  }
                                </CustomTooltip>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : null}
                  </Fragment>
                ))}
              </ThemeProvider>
            </FormControl>
          )}
        </Grid>
      )}
    </>
  );
};
