import { Box, IconButton } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { useEffect, useState } from 'react';
import SideBarNavigationMenu from './SideBarNavigationMenu';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SidebarLogo from './SidebarLogo';

const drawerWidth = 240;
type SideNavigationProps = {
  opnLgnFun: (val: boolean) => void;
  activeNav: string;
};
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const SideNavigation = ({ opnLgnFun, activeNav }: SideNavigationProps) => {
  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem('sideNav', 'true');
  };

  useEffect(() => {
    const sideNavOpen = localStorage.getItem('sideNav');
    if (!sideNavOpen) {
      localStorage.setItem('sideNav', 'true');
    }
  }, []);

  const [open, setOpen] = useState(localStorage.getItem('sideNav') === 'true');
  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem('sideNav', 'false');
  };
  return (
    <Drawer variant="permanent" open={open}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={open ? 'row' : 'column'}
        mt={1}
      >
        <SidebarLogo open={open} />
        <DrawerHeader>
          {open ? (
            <IconButton
              onClick={handleDrawerClose}
              sx={{ height: 'fit-content', p: 1 }}
            >
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleDrawerOpen}
              sx={{ height: 'fit-content' }}
            >
              <ChevronRightIcon />
            </IconButton>
          )}
        </DrawerHeader>
      </Box>
      <Box height={'100%'}>
        <SideBarNavigationMenu
          open={open}
          activeNav={activeNav}
          loginModal={opnLgnFun}
        />
      </Box>
    </Drawer>
  );
};

export default SideNavigation;
