import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { GetMTVPSTErminal } from 'services/apiServices';
import { Errors, GetMTVPSTErminalResult } from 'types/ApiServicesTypes';

export function useGetMTVPSTErminal() {
  const { showAlert } = useContext(ToastContext);

  const mutation = useMutation<
    GetMTVPSTErminalResult,
    Errors,
    Pick<{ ubiId: number; key: string }, 'ubiId' | 'key'>
  >('GetMTVPSTerminal', values =>
    GetMTVPSTErminal(values.ubiId, values.key)
      .then(res => {
        if (res?.error) {
          showAlert!(res.error);
        }
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}

export function useGetMTVPSTErminalStatus(
  ubiId: number | null,
  environment?: string,
) {
  const { showAlert } = useContext(ToastContext);
  const objQuery = useQuery<GetMTVPSTErminalResult, Errors>(
    ['GetMTVPSTerminalStatus', ubiId],
    () => GetMTVPSTErminal(Number(ubiId), 'is_active'),
    { enabled: ubiId !== null && environment == 'live-mt5' },
  );
  if (objQuery.isError && objQuery.error) {
    showAlert!(objQuery.error.message);
  }

  return objQuery;
}
