import {
  Errors,
  AuthenticateDefualtParams,
  AuthenticateDefualtResult,
  AuthenticateParams,
  AuthenticateWithGoogleParams,
  AuthenticateWithGoogleResult,
  EmailActivationParam,
  EmailActivationResult,
  GetAllBotsParams,
  GetBotHistoryResult,
  GetBotHistoryDetailResult,
  GetBotDetailsResult,
  GetBotPositionsParams,
  GetBotPositionsResult,
  GetBotOrdersParams,
  GetBotOrdersResult,
  GetPortfolioParams,
  GetPortfolioBotResult,
  GetPortfolioBotDetailResult,
  SignupWithGoogleResult,
  SignupWithGoogleParams,
  VerifyPhoneResult,
  VerifyPhoneParams,
  VerifyOtpParams,
  VerifyOtpResult,
  MobileSignupParams,
  MobileSignupResult,
  ForgotPassResult,
  ForgotPassParams,
  ResetConfirmOtpResult,
  ResetConfirmOtpParams,
  ResetPassResult,
  ResetPassParams,
  GetMarketplaceBotsParams,
  GetMarketplaceBotsResult,
  GetMarketplaceBotsByCategoryParams,
  GetMarketplaceBotsByCategoryResult,
  GetMarketplaceBotsBySearchParams,
  GetMarketplaceBotsBySearchResult,
  GetMarketplaceBotDetailParams,
  GetStatisticsResult,
  StatisticsData,
  GetStatisticsParams,
  GetProfileResult,
  EditProfileParams,
  EditProfileResult,
  ChangePasswordParams,
  ChangePasswordResult,
  GetBacktestBotDetailParams,
  GetBacktestBotDetailResult,
  PostBotActionParams,
  PostBotActionResult,
  DeleteBotResult,
  GetMarketplacEquityGraphResult,
  GetNotificationsResult,
  ReadNotificationsParams,
  ReadNotificationsResult,
  ReadAllNotificationsParams,
  ReadAllNotificationsResult,
  GetOtpParams,
  GetOtpResult,
  GetBrokersResult,
  GetBrokerFormResult,
  BuildRestRequestParams,
  CreateBrokerParams,
  CreateBrokerResult,
  GetBrokerByIdResult,
  DeleteBrokerResult,
  SaveUserBotResult,
  SaveUserBotParams,
  GetUserBotResult,
  PostUserBotIsExistResult,
  PostUserBotIsExistParams,
  GetStrategyDataByUserStrategyResult,
  GetStrategyDataByUserStrategyParms,
  GetProfitLossByDateResult,
  GetAuthorResult,
  AuthorPictureResult,
  GetEventsResult,
  GetBacktestQueueResult,
  PutCancelBacktestQueueResult,
  PutCancelBacktestQueueParams,
  AuthBrokerParams,
  AuthBrokerResult,
  GetBrokerAuthUrlResult,
  PostClearErrorResult,
  PostClearErrorParams,
  BrokerLogoutParams,
  BrokerLogoutResult,
  GetSiteInfoResult,
  GetProfileFeaturesResult,
  PostBotExitAllAndStopResult,
  PostBotSignalParams,
  PostBotSignalResult,
  GetBotSignalsResult,
  GetBotTodaysPositionsResult,
  GetBotTodaysOrdersResult,
  PostBotExitStrategyPositionsResult,
  PostBotExitStrategyPositionsParams,
  PostUsersBotActionParams,
  PostUsersBotActionResult,
  GetInvoicesResult,
  GetInvoicesParams,
  GetNotificationSettingsResult,
  EditNotificationParams,
  ActivateFreeTrialResult,
  GetReportDropDownData,
  GetBotBuilderFormResult,
  ReferralValidateResult,
  SetReferralResult,
  GetReferralDetails,
  GetEstimatedCreditResult,
  GetBotCardDetailsResult,
  GetBotsLogResult,
  GetActionParams,
  GetPaidAmountResult,
  PostCreditTransferResult,
  PostCreditTransferData,
  GetPortfolioShareResult,
  PostPortfolioShareResults,
  PostPortfolioShareParams,
  // GetStrategyParameterURL,
  GetAccountDeleteResult,
  GetTicketsDataResult,
  PostTicketData,
  PostTicketDataResult,
  PostTicketAttachmentData,
  GetTicketMessageResult,
  PostTicketMessageData,
  PostTicketMessageDataResponse,
  GetHomePageDataResult,
  GetLogoutResult,
  PostPortfolioManualBotResults,
  PostPortfolioManualBotParams,
  GetPortfolioBotResultV2,
  GetOptionHelpResult,
  GetBotsLogFilesDataResult,
  GetWebHookAuthKeyResult,
  Position,
  GetBotTypeResult,
  GetOptionChainMetadataResult,
  GetOptionChainResultDataType,
  ImportStrategyResult,
  GetMTMDataResult,
  GetTradeIdeasResult,
  GetTradeOptionsResult,
  GetOptionChainDataResult,
  GetTradesIdeasMetadataResult,
  GetStrategtParameterKeyValueResult,
  GetFetchIframUrlResult,
  GetUserActiveStatusResult,
  TGetOptionSymbolsResult,
  TWebHookBotResult,
  TWebHookSignalResult,
  TWebHookActionParams,
  TWebHookActionResult,
  TOTPVarificationParams,
  TOTPVarificationResult,
  GetMTVPSTErminalResult,
} from '../types/ApiServicesTypes';
import { getCookie, setCookie } from 'typescript-cookie';
import Cookies from 'js-cookie';
import {
  API_FETCH_TIMEOUT,
  SESSION_ID,
  ACTIVE_USER,
  USER_NAME,
  USER_ID,
} from 'constants/common';
import { handleUnauthorized } from './Auth';
import { UserInfo } from 'types/User';
import {
  GetPlansResult,
  SubscribePlanStatusParams,
  SubscribePlanStatusResult,
  SubscribePlanParams,
  SubscribePlanResult,
  CancelPlanParams,
  CancelPlanResult,
  WalletActionParams,
  WalletActionResult,
  WalletStatusParams,
  WalletStatusResult,
  GetWalletTransactionResult,
} from 'types/SubscriptionPlans';
import { GetInstrumentsResult } from 'types/Instrument';
import { SymbolData, SymbolsData } from 'types/Symbols';
import { TradeIdeasFilterParam } from './TradeIdeas/useGetTradeIdeasData';
import { PostStrategyDataPickerProps } from './Bots/usePostStrategyData';

// Constants
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL!;
const API_BASE_URL = '/api';

// eslint-disable-next-line
function BuildRequest(params) {
  return JSON.stringify({
    jsonrpc: '2.0',
    method: 'call',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    params: params, // payload
  });
}

function BuildRestRequest(params: BuildRestRequestParams) {
  return JSON.stringify({
    params: params,
  });
}

function timeout(ms: number, promise: Promise<Response>) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('TIMEOUT'));
    }, ms);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    promise
      .then(value => {
        clearTimeout(timer);
        resolve(value);
      })
      .catch(reason => {
        clearTimeout(timer);
        reject(reason);
      });
  });
}

export function IsLoggedIn() {
  return localStorage.getItem(SESSION_ID) ? true : false;
}

function HandleOdooErrors(response): Errors {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const error = response.error;
  const objError = {
    title: '    ',
    message: '',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    fullTrace: error,
  };

  if (
    error.code === 200 &&
    error.message === 'Odoo Server Error' &&
    error.data.name === 'werkzeug.exceptions.NotFound'
  ) {
    objError.title = 'page_not_found';
    objError.message = 'HTTP Error';
  } else if (
    error.code === 200 &&
    error.message === 'Odoo Server Error' &&
    (error.data.name === 'builtins.ValueError' ||
      error.data.name === 'odoo.exceptions.AccessDenied')
  ) {
    objError.title = 'Authentication Failed';
    objError.message = error.data.message as string;
  } else if (
    (error.code === 100 && error.message === 'Odoo Session Expired') || // v8
    (error.code === 300 &&
      error.message === 'OpenERP WebClient Error' &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      error.data.debug.match('SessionExpiredException')) ||
    error.code === 401 // v7
  ) {
    handleUnauthorized();
  } else if (
    error.message === 'Odoo Server Error' &&
    /FATAL: database "(.+)" does not exist/.test(error.data.message as string)
  ) {
    objError.title = 'database_not_found';
    objError.message = error.data.message as string;
  } else if (error.data.name === 'openerp.exceptions.AccessError') {
    objError.title = 'AccessError';
    objError.message = error.data.message as string;
  } else {
    const split = (error.data.fault_code as string)
      .split('\n')[0]
      .split(' -- ');
    if (split.length > 1) {
      error.type = split.shift();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      error.data.fault_code = error.data.fault_code.substr(
        (error.type.length as number) + 4,
      ) as string;
    }

    if (error.code === 200 && error.type) {
      objError.title = error.type as string;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      objError.message = error.data.fault_code.replace(
        /\n/g,
        '<br />',
      ) as string;
    } else {
      objError.title = error.message as string;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      objError.message = error.data.debug.replace(/\n/g, '<br />') as string;
    }
  }
  return objError;
}

function HandleAPIErrors(response): Errors {
  const error: string = (response.statusText as string) || '';
  const statusCode: number = response.status as number;
  const objError = {
    title: '    ',
    message: '',
    fullTrace: error,
  };

  if (statusCode === 401) {
    handleUnauthorized();
    objError.title = '';
    objError.message = '';
  } else {
    if (!navigator.onLine) {
      objError.title = '';
      objError.message = '';
    } else {
      objError.title = 'Unknown error. Please try again.';
      objError.message = 'Unknown error. Please try again.';
    }
  }
  return objError;
}

type FetchOptionType = {
  method: string;
  headers: {
    'Content-Type': string;
    'X-Openerp-Session-Id': string;
  };
  body?: string | null;
};

export async function MakeApiRequest<P, R>(
  path: string,
  params: P = {} as P,
  method: string,
  content_type: string,
): Promise<{ result?: R; error?: Errors }> {
  try {
    try {
      let session_id = getCookie(SESSION_ID) as string;
      if (!session_id) {
        session_id = '';
      }
      const headers = {
        'Content-Type': content_type,
        'X-Openerp-Session-Id': session_id,
      };
      const fetchOptions: FetchOptionType = {
        method: method,
        headers: headers,
      };
      if (
        params &&
        (method.toLowerCase() === 'post' || method.toLowerCase() === 'put')
      )
        fetchOptions.body = BuildRestRequest(params);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await timeout(
        API_FETCH_TIMEOUT,
        fetch(`${API_BASE_URL}/${path}`, fetchOptions),
      );

      if (!response.ok) {
        const objErrorMessage = HandleAPIErrors(response);
        return { error: objErrorMessage };
      }
      // eslint-disable-next-line
      const rresponse = await response.json();

      if (rresponse.result && rresponse.result.session_id) {
        localStorage.setItem(SESSION_ID, rresponse.result.session_id as string);
      }

      if (!rresponse.error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        return { result: rresponse.result };
      } else {
        return { error: HandleOdooErrors(rresponse) };
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if ((err && err.message === 'TIMEOUT') || !navigator.onLine)
        return { error: { title: '', message: '' } };
      return {
        error: {
          title: 'Unknown error. Please try again.',
          message: 'Unknown error. Please try again.',
        },
      };
    }
  } catch (error) {
    return {
      error: {
        title: 'Api request error',
        message: 'Api request error',
        error: error,
      },
    };
  }
}

export async function RestRequest<P, R>(
  path: string,
  params: P = {} as P,
  method: string,
  contentType: string,
  v2 = false,
  baseURL?: string,
): Promise<{ result?: R; error?: Errors }> {
  try {
    try {
      let session_id = getCookie(SESSION_ID) as string;
      if (!session_id) {
        session_id = '';
        // handleUnauthorized();
      }
      const headers = {
        'Content-Type': contentType,
        'X-Openerp-Session-Id': session_id,
      };

      let response;
      try {
        if (contentType === 'text/html') {
          response = await timeout(
            API_FETCH_TIMEOUT,
            fetch(`${baseURL ? baseURL : API_BASE_URL}/${path}`, {
              method: method,
              headers: headers,
            }),
          );
        } else {
          const fetchOptions: FetchOptionType = {
            method: method,
            headers: headers,
          };
          if (
            params &&
            (method.toLowerCase() === 'post' || method.toLowerCase() === 'put')
          )
            fetchOptions.body = BuildRestRequest(params);

          response = await timeout(
            API_FETCH_TIMEOUT,
            fetch(`${baseURL ? baseURL : API_BASE_URL}/${path}`, fetchOptions),
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if ((err && err.message === 'TIMEOUT') || !navigator.onLine)
          return { error: { title: '', message: '' } };
        return {
          error: {
            // title: 'Network unreachable. Check your connection.',
            // message: 'Network unreachable. Check your connection.',
            title: 'Unknown error. Please try again.',
            message: 'Unknown error. Please try again.',
          },
        };
      }

      if (!response.ok) {
        const objErrorMessage = HandleAPIErrors(response);
        return { error: objErrorMessage };
      }

      // eslint-disable-next-line
      response = await response.json();
      if (response.total_pages) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const objResult = response.result
          ? response.result
          : response.data
          ? response.data
          : response;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        objResult.map(val => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          val['total_pages'] = response.total_pages;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          val['count'] = response.count;
          return val;
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        return { result: objResult };
      }
      if ((response.error && v2) || !response.error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        return { result: response.result ? response.result : response };
      } else {
        return { error: HandleOdooErrors(response) };
      }
    } catch (err) {
      return {
        error: {
          title: !navigator.onLine ? 'Unknown error. Please try again.' : '',
          message: !navigator.onLine ? 'Unknown error. Please try again.' : '',
        },
      };
    }
  } catch (error) {
    return {
      error: {
        title: 'Api request error',
        message: 'Api request error',
        error: error,
      },
    };
  }
}

export async function makeRestRequest<P, R>(
  path: string,
  params: P,
  method = 'GET',
  contentType = 'application/json',
) {
  const response = await RestRequest<P, R>(path, params, method, contentType);

  if (response.result) {
    return response.result;
  } else {
    if (!navigator.onLine) {
      throw new Error('');
    } else if (response.error) {
      throw new Error(response.error.message);
    } else {
      throw new Error('Unknown error. Please try again.');
    }
  }
}

export async function makePostRestRequest<P, R>(
  path: string,
  params: P,
  contentType?: string,
) {
  return await makeRestRequest<P, R>(path, params, 'POST', contentType);
}

export async function makePutRestRequest<P, R>(
  path: string,
  params: P,
  contentType?: string,
) {
  return await makeRestRequest<P, R>(path, params, 'PUT', contentType);
}

export async function makeGetRestRequest<P, R>(
  path: string,
  params: P,
  contentType?: string,
) {
  return await makeRestRequest<P, R>(path, params, 'GET', contentType);
}

export async function makeDeleteRestRequest<P, R>(
  path: string,
  params: P,
  contentType?: string,
) {
  return await makeRestRequest<P, R>(path, params, 'DELETE', contentType);
}

export async function Authenticate(
  username: string,
  password: string,
): Promise<UserInfo> {
  const params: AuthenticateParams = {
    userid: username,
    password: password,
  };

  const response = await MakeApiRequest<AuthenticateParams, UserInfo>(
    'signin',
    params,
    'POST',
    'application/json',
  );

  if (response.result) {
    if (!response.result.error) {
      localStorage.setItem(ACTIVE_USER, JSON.stringify(response.result));
      // setCookie(SESSION_ID, response.result.session_id, { expires: 2592000 });
      // setCookie(USER_NAME, response.result.username, { expires: 2592000 });
      // setCookie(USER_ID, response.result.uid.toString(), {
      //   expires: 2592000,
      // });
      /* eslint-disable  @typescript-eslint/no-unsafe-call */
      Cookies.set(SESSION_ID, response.result.session_id, { expires: 2592000 });
      Cookies.set(USER_NAME, response.result.username, { expires: 2592000 });
      Cookies.set(USER_ID, response.result.uid.toString(), {
        expires: 2592000,
      });
      /* eslint-enable @typescript-eslint/no-unsafe-call */
    }
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function AuthenticateDefault(
  sessionid: string,
  method: string,
): Promise<AuthenticateDefualtResult> {
  const headers: AuthenticateDefualtParams = {
    sessionid: sessionid,
  };

  const response = await RestRequest<
    AuthenticateDefualtParams,
    AuthenticateDefualtResult
  >('session/verify', headers, method, 'text/html');

  if (response.result) {
    localStorage.setItem(ACTIVE_USER, JSON.stringify(response.result));
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function AuthenticateWithGoogle(
  method: string,
  email: string,
  access_token: string,
  token_type: string,
  expires_in: string,
  scope: string,
): Promise<AuthenticateWithGoogleResult> {
  const params: AuthenticateWithGoogleParams = {};
  const response = await MakeApiRequest<
    AuthenticateWithGoogleParams,
    AuthenticateWithGoogleResult
  >(
    `login/google?state={"p": 3, "email": "${email}"}&access_token=${access_token}&token_type=${token_type}&expires_in=${expires_in}&scope=${scope}`,
    params,
    method,
    'text/html',
  );

  if (response.result) {
    localStorage.setItem(ACTIVE_USER, JSON.stringify(response.result));
    if (
      response.result.session_id &&
      response.result.uid &&
      response.result.username
    ) {
      setCookie(SESSION_ID, response.result.session_id);
      setCookie(USER_NAME, response.result.username.toString());
      setCookie(USER_ID, response.result.uid.toString());
    }
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function AuthenticateWithApple(
  code: string,
  idToken: string,
  bundleId?: string,
): Promise<AuthenticateWithGoogleResult> {
  let path = `login/apple?code=${code}&id_token=${idToken}`;
  if (bundleId) path += `&bundle_id=${bundleId}`;

  const response = await RestRequest<undefined, AuthenticateWithGoogleResult>(
    path,
    undefined,
    'GET',
    'text/html',
    true,
  );

  if (response.result) {
    localStorage.setItem(ACTIVE_USER, JSON.stringify(response.result));
    if (
      response.result.session_id &&
      response.result.uid &&
      response.result.username
    ) {
      setCookie(SESSION_ID, response.result.session_id);
      setCookie(USER_NAME, response.result.username.toString());
      setCookie(USER_ID, response.result.uid.toString());
    }
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function SignupWithGoogle(
  method: string,
  email: string,
  access_token: string,
  token_type: string,
  expires_in: string,
  scope: string,
): Promise<SignupWithGoogleResult> {
  const params: SignupWithGoogleParams = {};
  const response = await MakeApiRequest<
    SignupWithGoogleParams,
    SignupWithGoogleResult
  >(
    `signup/oauth/google?state={"p": 3, "email": "${email}"}&access_token=${access_token}&token_type=${token_type}&expires_in=${expires_in}&scope=${scope}`,
    params,
    method,
    'text/html',
  );

  if (response.result) {
    localStorage.setItem(ACTIVE_USER, JSON.stringify(response.result));
    if (
      response.result.session_id &&
      response.result.uid &&
      response.result.username
    ) {
      setCookie(SESSION_ID, response.result.session_id);
      setCookie(USER_NAME, response.result.username.toString());
      setCookie(USER_ID, response.result.uid.toString());
    }
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function VerifyPhone(
  country_code: string,
  phone: string,
  email: string,
): Promise<VerifyPhoneResult> {
  const params: VerifyPhoneParams = {
    country_code: country_code,
    mobile: phone.toString(),
    email: email,
  };
  const response = await MakeApiRequest<VerifyPhoneParams, VerifyPhoneResult>(
    'signup/otp',
    params,
    'POST',
    'application/json',
  );

  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function VerifyOtp(
  country_code: string,
  phone: string,
  otp: string,
): Promise<VerifyOtpResult> {
  const params: VerifyOtpParams = {
    country_code: country_code,
    mobile: phone,
    otp: otp,
  };
  const response = await MakeApiRequest<VerifyOtpParams, VerifyOtpResult>(
    'signup/otp/verify',
    params,
    'POST',
    'application/json',
  );

  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function MobileSignup(
  name: string,
  email: string,
  country_code: string,
  mobile: string,
  password: string,
  referral: string,
): Promise<MobileSignupResult> {
  const params: MobileSignupParams = {
    name: name,
    email: email,
    country_code: country_code,
    mobile: mobile,
    password: password,
    referral: referral,
  };

  const response = await MakeApiRequest<MobileSignupParams, MobileSignupResult>(
    'signup/mobile',
    params,
    'POST',
    'application/json',
  );

  if (response.result) {
    localStorage.setItem(ACTIVE_USER, JSON.stringify(response.result));
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function ForgotPass(
  params: ForgotPassParams,
): Promise<ForgotPassResult> {
  const response = await MakeApiRequest<ForgotPassParams, ForgotPassResult>(
    'reset',
    params,
    'POST',
    'application/json',
  );

  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function ResetConfirmOtp(
  country_code: string,
  phone: string,
  otp: string,
): Promise<ResetConfirmOtpResult> {
  const params: ResetConfirmOtpParams = {
    country_code: country_code,
    mobile: phone,
    otp: otp,
  };

  const response = await MakeApiRequest<
    ResetConfirmOtpParams,
    ResetConfirmOtpResult
  >('reset', params, 'POST', 'application/json');

  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function ResetPass(
  country_code: string,
  phone: string,
  otp: string,
  password: string,
): Promise<ResetPassResult> {
  const params: ResetPassParams = {
    country_code: country_code,
    mobile: phone,
    otp: otp,
    password: password,
  };

  const response = await MakeApiRequest<ResetPassParams, ResetPassResult>(
    'reset',
    params,
    'POST',
    'application/json',
  );

  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function EmailActivation(
  login: string,
  token: string,
): Promise<EmailActivationResult> {
  const params: EmailActivationParam = {
    args: {
      login: login,
    },
    kwargs: {
      token: token,
    },
  };

  const response = await MakeApiRequest<
    EmailActivationParam,
    EmailActivationResult
  >('web/sign_up_activation', params, 'POST', 'application/json');
  if (response.result) {
    localStorage.setItem(ACTIVE_USER, JSON.stringify(response.result));
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetAllBots(
  model: string,
  method: string,
  page?: number,
  search?: string,
  type?: string,
): Promise<GetBotCardDetailsResult> {
  const basePath = `${model}/v2?page=${page}`;
  const searchQuery = search ? `&search=${search}` : '';
  const typeQuery = type === 'all' || !type ? '' : `&type=${type}`;

  const path = basePath + searchQuery + typeQuery;
  const response = await RestRequest<GetAllBotsParams, GetBotCardDetailsResult>(
    path,
    undefined,
    method,
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetBotHisory(bId: number): Promise<GetBotHistoryResult> {
  const path = `bots/v2/history/${bId}`;
  return await makeGetRestRequest<undefined, GetBotHistoryResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function GetBotHistoryDetail(
  model: string,
  method: string,
): Promise<GetBotHistoryDetailResult> {
  const response = await RestRequest<
    GetAllBotsParams,
    GetBotHistoryDetailResult
  >(`${model}`, undefined, method, 'text/html');
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error, Please try again');
  }
}

export async function GetBotDetails(
  bId: number,
  sId: number,
): Promise<GetBotDetailsResult> {
  const path = `bots/v2/${bId}/${sId ? +sId : ''}`;
  return await makeGetRestRequest<undefined, GetBotDetailsResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function GetStatistics(
  model: string,
  method: string,
): Promise<GetStatisticsResult> {
  const response = await RestRequest<GetStatisticsParams, GetStatisticsResult>(
    `${model}/statistics`,
    undefined,
    method,
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetBotPositions(
  model: string,
  method: string,
  id: number,
  page: number,
  pageSize: number,
): Promise<GetBotPositionsResult> {
  const response = await RestRequest<
    GetBotPositionsParams,
    GetBotPositionsResult
  >(
    `${model}/positions?page=${page}&page_size=${pageSize}&meta_info=true`,
    undefined,
    method,
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetBotPositionsForExcel(
  _model: string,
  method: string,
  id: number,
  startDate: string,
  endDate: string,
  withFees: boolean,
  period: string,
  with_percentage: boolean,
  tickdata: SymbolsData,
) {
  const tickStrings = Object.values(tickdata)
    .map((tick: SymbolData) => {
      if (tick === undefined) return;
      return `${tick.tickname}|${tick.Close}|${tick.LastClose}`;
    })
    .filter(Boolean);

  const response = await DownloadRestRequest<undefined, Blob>(
    `backtest/excel/${id}/download?start_date=${startDate}&end_date=${endDate}&with_fees=${
      withFees ? 1 : 0
    }&time_period=${period}&with_percentage=${
      with_percentage ? 1 : 0
    }&ticks=${tickStrings}`,
    undefined,
    method,
    'text/html',
  );

  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function DownloadBotExcel(subId: number) {
  const response = await DownloadRestRequest<undefined, Blob>(
    `subscription/excel/${subId}/download`,
    undefined,
    'GET',
    'text/html',
  );

  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetBotOrders(
  model: string,
  method: string,
  id: number,
  page: number,
  pageSize: number,
): Promise<GetBotOrdersResult> {
  const response = await RestRequest<GetBotOrdersParams, GetBotOrdersResult>(
    `${model}/orders?page=${page}&page_size=${pageSize}&meta_info=true`,
    undefined,
    method,
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetBotTodaysPositions(
  botId: number,
): Promise<GetBotTodaysPositionsResult> {
  const path = `strategies/${botId}/positions`;
  return await makeGetRestRequest<undefined, GetBotTodaysPositionsResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function GetBotTodaysOrders(
  botId: number,
): Promise<GetBotTodaysOrdersResult> {
  const path = `strategies/${botId}/orders`;
  return await makeGetRestRequest<undefined, GetBotTodaysOrdersResult>(
    path,
    undefined,
    'text/html',
  );
}

// ============================================
// Portfolio apis
// ============================================

export async function GetPortfolioActive(
  model: string,
  method: string,
): Promise<GetPortfolioBotResult> {
  const response = await RestRequest<GetPortfolioParams, GetPortfolioBotResult>(
    `${model}`,
    undefined,
    method,
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetPortfolioPaper(
  model: string,
  method: string,
): Promise<GetPortfolioBotResult> {
  const response = await RestRequest<GetPortfolioParams, GetPortfolioBotResult>(
    `${model}`,
    undefined,
    method,
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetPortfolio(): Promise<GetPortfolioBotResult> {
  const path = `portfolio`;
  return await makeGetRestRequest<undefined, GetPortfolioBotResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function GetPortfolioBotDetail(
  model: string,
  method: string,
): Promise<GetPortfolioBotDetailResult> {
  const response = await RestRequest<
    GetPortfolioParams,
    GetPortfolioBotDetailResult
  >(`${model}`, undefined, method, 'text/html');
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function PostClearError(
  subId: number,
  errorId: number,
): Promise<PostClearErrorResult> {
  const params = {
    read: true,
  };

  const path = `portfolio/${subId}/errors/${errorId}`;
  return await makePostRestRequest<PostClearErrorParams, PostClearErrorResult>(
    path,
    params,
  );
}

export async function GetMarketplaceBots(
  model: string,
  method: string,
  withOutFees: boolean,
): Promise<GetMarketplaceBotsResult> {
  const response = await RestRequest<
    GetMarketplaceBotsParams,
    GetMarketplaceBotsResult
  >(
    `${model}/bots?without_fees=${withOutFees}`,
    undefined,
    method,
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetMarketplaceBotsByCategory(
  model: string,
  method: string,
  id: number,
): Promise<GetMarketplaceBotsByCategoryResult> {
  const response = await RestRequest<
    GetMarketplaceBotsByCategoryParams,
    GetMarketplaceBotsByCategoryResult
  >(`${model}/bots/categories/${id}`, undefined, method, 'text/html');
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetMarketplaceBotsBySearch(
  model: string,
  method: string,
  keyword: string,
): Promise<GetMarketplaceBotsBySearchResult> {
  const response = await RestRequest<
    GetMarketplaceBotsBySearchParams,
    GetMarketplaceBotsBySearchResult
  >(`${model}/bots/search?keyword=${keyword}`, undefined, method, 'text/html');
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetMarketplaceBotDetail(
  model: string,
  method: string,
  id: number,
  type = '',
): Promise<StatisticsData> {
  let path = `${model}/bots/${id}`;
  if (type) path += `?type=${type}`;
  const response = await RestRequest<
    GetMarketplaceBotDetailParams,
    StatisticsData
  >(path, undefined, method, 'text/html');
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetProfile(): Promise<GetProfileResult> {
  const response = await RestRequest<null, GetProfileResult>(
    `profile`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetNotificationSettings(): Promise<GetNotificationSettingsResult> {
  const response = await RestRequest<null, GetNotificationSettingsResult>(
    `notification/settings`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function EditNotificationSettings(
  settings: EditNotificationParams,
): Promise<boolean> {
  const response = await MakeApiRequest<EditNotificationParams, boolean>(
    'notification/settings',
    settings,
    'POST',
    'application/json',
  );

  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
export async function EditProfile(
  name?: string | undefined,
  email?: string | undefined,
  gstin?: string | undefined,
  mobile?: string | undefined,
  otp?: string | undefined,
  country_code?: string | undefined,
  generalNotification?: boolean | undefined,
  paperNotification?: boolean | undefined,
  liveNotification?: boolean | undefined,
): Promise<EditProfileResult> {
  const params: EditProfileParams = {};
  if (name) params.name = name;
  if (email) params.email = email;
  if (gstin || gstin == '') params.gstin = gstin;
  if (mobile) params.mobile = mobile;
  if (otp) params.otp = otp;
  if (country_code) params.country_code = country_code;
  if (generalNotification !== undefined && generalNotification !== null)
    params.general_notification = generalNotification;
  if (paperNotification !== undefined && paperNotification !== null)
    params.paper_notification = paperNotification;
  if (liveNotification !== undefined && liveNotification !== null)
    params.live_notification = liveNotification;

  const response = await RestRequest<EditProfileParams, EditProfileResult>(
    `profile/update`,
    params,
    'POST',
    'application/json',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetOtp(
  mobile?: string | undefined,
  ccode?: string | undefined,
): Promise<GetOtpResult> {
  const params: GetOtpParams = {
    mobile: mobile,
    country_code: ccode,
  };
  const response = await RestRequest<GetOtpParams, GetOtpResult>(
    'profile/otp',
    params,
    'POST',
    'application/json',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error, Please try again.');
  }
}

export async function getProfileFeatures(): Promise<GetProfileFeaturesResult> {
  const path = `profile/features`;
  return await makeGetRestRequest<undefined, GetProfileFeaturesResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function ChangePassword(
  password: string,
  new_password: string,
): Promise<ChangePasswordResult> {
  const params: ChangePasswordParams = {
    password: password,
    new_password: new_password,
  };
  const response = await RestRequest<
    ChangePasswordParams,
    ChangePasswordResult
  >(`profile/update/password`, params, 'POST', 'application/json');
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetBacktestBotDetail(
  model: string,
  method: string,
  id: number,
): Promise<GetBacktestBotDetailResult> {
  const response = await RestRequest<
    GetBacktestBotDetailParams,
    GetBacktestBotDetailResult
  >(`${model}/${id}/backtest`, undefined, method, 'text/html');
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function PostBotAction(
  model: string,
  id: number,
  params: PostBotActionParams,
): Promise<PostBotActionResult> {
  const path = `${model}/${id}/action`;
  return await makePostRestRequest<PostBotActionParams, PostBotActionResult>(
    path,
    params,
  );
}

export async function PostBotExitAllAndStop(
  model: string,
  id: number,
): Promise<PostBotExitAllAndStopResult> {
  const path = `${model}/bots/${id}/exit_all`;
  return await makePostRestRequest<undefined, PostBotExitAllAndStopResult>(
    path,
    undefined,
  );
}

export async function PostBotExitStrategyPositions(
  id: number,
  positionIds: number[],
): Promise<PostBotExitStrategyPositionsResult> {
  const path = `strategies/${id}/positions`;
  const params: PostBotExitStrategyPositionsParams = {
    position_ids: positionIds,
    action: 'exit',
  };

  return await makePostRestRequest<
    PostBotExitStrategyPositionsParams,
    PostBotExitStrategyPositionsResult
  >(path, params);
}
export async function PortfolioExitStrategyPositions(
  id: number,
  positionIds: number[],
): Promise<PostBotExitStrategyPositionsResult> {
  const path = `subscription/${id}/positions`;
  const params: PostBotExitStrategyPositionsParams = {
    position_ids: positionIds,
    action: 'exit',
  };

  return await makePostRestRequest<
    PostBotExitStrategyPositionsParams,
    PostBotExitStrategyPositionsResult
  >(path, params);
}

export async function DeleteBot(id: number): Promise<DeleteBotResult> {
  const path = `bots/${id}`;
  return await makeDeleteRestRequest<undefined, DeleteBotResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function PostUsersBotAction(
  id: number,
  params: PostUsersBotActionParams,
): Promise<PostUsersBotActionResult> {
  const path = `users/bots/${id}/action`;
  return await makePostRestRequest<
    PostUsersBotActionParams,
    PostUsersBotActionResult
  >(path, params);
}

export async function GetBotSignals(
  id: number,
  page: number,
  pageSize: number,
): Promise<GetBotSignalsResult> {
  const path = `bots/${id}/signal?page=${page}&page_size=${pageSize}`;
  return await makeGetRestRequest<undefined, GetBotSignalsResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function PostBotSignal(
  id: number,
  params: PostBotSignalParams,
): Promise<PostBotSignalResult> {
  const path = `bots/${id}/signal`;
  return await makePostRestRequest<PostBotSignalParams, PostBotSignalResult>(
    path,
    params,
  );
}

export async function GetMarketplacEquityGraph(
  // id: number,
  baseApiUrl: string,
  withOutFees: boolean,
): Promise<GetMarketplacEquityGraphResult> {
  const path = `${baseApiUrl}/graphs/equity?data_points=100&without_fees=${withOutFees}`;
  return await makeGetRestRequest<undefined, GetMarketplacEquityGraphResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function getMarketPlaceBotGraphData<T>(
  // id: number,
  baseApiUrl: string,
  type: string,
  withOutFees: boolean,
): Promise<T> {
  const path = `${baseApiUrl}/graphs/${type}?data_points=100&without_fees=${withOutFees}`;
  return await makeGetRestRequest<undefined, T>(path, undefined, 'text/html');
}

export async function GetNotifications(): Promise<GetNotificationsResult> {
  const userId = getCookie(USER_ID) as string;
  const path = `notifications/users/${userId}`;
  return await makeGetRestRequest<undefined, GetNotificationsResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function ReadNotifications(
  id: number,
): Promise<ReadNotificationsResult> {
  const params: ReadNotificationsParams = {
    read: true,
  };
  const path = `notification/${id}`;
  return await makePostRestRequest<
    ReadNotificationsParams,
    ReadNotificationsResult
  >(path, params);
}

export async function ReadAllNotifications(): Promise<ReadAllNotificationsResult> {
  const params: ReadAllNotificationsParams = {
    read: true,
  };
  const path = `notifications/readall`;
  return await makePostRestRequest<
    ReadAllNotificationsParams,
    ReadAllNotificationsResult
  >(path, params);
}

export async function GetBrokers(params?: string): Promise<GetBrokersResult> {
  const userId = getCookie(USER_ID) as string;
  const pathWithParam = params
    ? `users/${userId}/brokers?exchange=${params}`
    : `users/${userId}/brokers`;
  const path = pathWithParam;
  return await makeGetRestRequest<undefined, GetBrokersResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function GetBrokerById(id: number): Promise<GetBrokerByIdResult> {
  const userId = getCookie(USER_ID) as string;
  const path = `users/${userId}/brokers/${id}`;
  return await makeGetRestRequest<undefined, GetBrokerByIdResult>(
    path,
    undefined,
    'text/html',
  );
}
export async function GetBrokerForm(): Promise<GetBrokerFormResult> {
  const path = `metadata/supported_brokers`;
  return await makeGetRestRequest<undefined, GetBrokerFormResult>(
    path,
    undefined,
    'text/html',
  );
}
``;

export async function GetMTVPSTErminal(
  ubiID: number,
  key: string,
): Promise<GetMTVPSTErminalResult> {
  const path = `metatrader5/${ubiID}/${key}`;
  const response = await RestRequest<undefined, GetMTVPSTErminalResult>(
    path,
    undefined,
    'GET',
    'text/html',
    true,
  );
  if (response.result) {
    return response.result;
  } else {
    if (!navigator.onLine) {
      throw new Error('');
    } else if (response.error) {
      throw new Error(response.error.message);
    } else {
      throw new Error('Unknown error. Please try again.');
    }
  }
}
export async function CreateBroker(
  values: CreateBrokerParams,
): Promise<CreateBrokerResult> {
  const path = 'users/brokers';
  const params: CreateBrokerParams = {
    name: values.name,
    broker: values.broker,
    exchange: values.exchange,
    auth1: values.auth1 ? values.auth1 : '',
    auth2: values.auth2 ? values.auth2 : '',
    auth3: values.auth3 ? values.auth3 : '',
    auth4: values.auth4 ? values.auth4 : '',
    auth5: values.auth5 ? values.auth5 : '',
    auth6: values.auth6 ? values.auth6 : '',
    auth7: values.auth7 ? values.auth7 : '',
    uuid: values.uuid!,
  };
  const response = await RestRequest<CreateBrokerParams, CreateBrokerResult>(
    path,
    params,
    'POST',
    'application/json',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function EditBroker(
  id: number,
  values: CreateBrokerParams,
): Promise<CreateBrokerResult> {
  const path = `users/brokers/${id}`;
  const params: CreateBrokerParams = {
    name: values.name?.trim(),
    broker: values.broker,
    exchange: values.exchange,
    auth1: values.auth1
      ? typeof values.auth1 === 'string'
        ? values.auth1.trim()
        : values.auth1
      : '',
    auth2: values.auth2
      ? typeof values.auth2 === 'string'
        ? values.auth2.trim()
        : values.auth2
      : '',
    auth3: values.auth3
      ? typeof values.auth3 === 'string'
        ? values.auth3.trim()
        : values.auth3
      : '',
    auth4: values.auth4
      ? typeof values.auth4 === 'string'
        ? values.auth4.trim()
        : values.auth4
      : '',
    auth5: values.auth5
      ? typeof values.auth5 === 'string'
        ? values.auth5.trim()
        : values.auth5
      : '',
    auth6: values.auth6
      ? typeof values.auth6 === 'string'
        ? values.auth6.trim()
        : values.auth6
      : '',
    auth7: values.auth7
      ? typeof values.auth7 === 'string'
        ? values.auth7.trim()
        : values.auth7
      : '',
  };
  const response = await RestRequest<CreateBrokerParams, CreateBrokerResult>(
    path,
    params,
    'POST',
    'application/json',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function DeleteBroker(id: number): Promise<DeleteBrokerResult> {
  const userId = getCookie(USER_ID) as string;
  const path = `users/${userId}/brokers/${id}`;
  return await makeDeleteRestRequest<undefined, DeleteBrokerResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function AuthBroker(
  values: AuthBrokerParams,
): Promise<AuthBrokerResult> {
  const path = `brokers/auth/${values.uuid}`;

  const response = await RestRequest<AuthBrokerParams, AuthBrokerResult>(
    path,
    values,
    'POST',
    'application/json',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetBrokerAuthUrl(
  id: number,
): Promise<GetBrokerAuthUrlResult> {
  const path = `brokers/getLoginUrl/${id}`;
  return await makeGetRestRequest<undefined, GetBrokerAuthUrlResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function BrokerLogout(id: number): Promise<BrokerLogoutResult> {
  const params = { id: id };
  const path = `users/brokers/${id}/logout`;
  return await makePostRestRequest<BrokerLogoutParams, BrokerLogoutResult>(
    path,
    params,
    'application/json',
  );
}

export async function GetPlans(couponCode?: string): Promise<GetPlansResult> {
  let path = `subscriptions/plans`;
  if (couponCode !== undefined) path += '?coupon_code=' + couponCode;
  return await makeGetRestRequest<undefined, GetPlansResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function SubscribePlan(
  planId: number,
  endDate: string,
  paymentMode: string,
  paymentAcquirer: string,
  couponCode: string,
): Promise<SubscribePlanResult> {
  const path = `subscriptions/plans`;
  const params: SubscribePlanParams = {
    plan_id: planId,
    end_date: endDate,
    payment_type: paymentMode,
    payment_acquirer: paymentAcquirer,
    coupon_code: couponCode,
  };
  const response = await RestRequest<SubscribePlanParams, SubscribePlanResult>(
    path,
    params,
    'POST',
    'application/json',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function SubscribePlanStatus(
  razorpayPaymentId: string,
  razorpaySubscriptionId: string,
  razorpaySignature: string,
  razorpayOrderId: string,
  invoice_id?: number,
  subscription_id?: number,
): Promise<SubscribePlanStatusResult> {
  const path = `subscriptions/status`;
  const params: SubscribePlanStatusParams = {
    razorpay_payment_id: razorpayPaymentId,
    razorpay_subscription_id: razorpaySubscriptionId,
    razorpay_signature: razorpaySignature,
    razorpay_order_id: razorpayOrderId,
    invoice_id: invoice_id,
    subscription_id: subscription_id,
  };
  const response = await RestRequest<
    SubscribePlanStatusParams,
    SubscribePlanStatusResult
  >(path, params, 'POST', 'application/json');
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function CancelPlan(
  subscriptionId: number,
): Promise<CancelPlanResult> {
  const path = `subscriptions/plan/cancel`;
  const params: CancelPlanParams = {
    subscription_id: subscriptionId,
  };
  const response = await RestRequest<CancelPlanParams, CancelPlanResult>(
    path,
    params,
    'POST',
    'application/json',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetInstruments(
  extraPatrams?: string,
): Promise<GetInstrumentsResult> {
  const path = `instrument${extraPatrams ? '?' + extraPatrams : ''}`;
  return await makeGetRestRequest<undefined, GetInstrumentsResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function SaveUserBot(
  params: SaveUserBotParams,
): Promise<SaveUserBotResult> {
  const path = `users/bots`;
  return await makePostRestRequest<SaveUserBotParams, SaveUserBotResult>(
    path,
    params,
  );
}

export async function EditUserBot(
  botId: number,
  params: SaveUserBotParams,
): Promise<SaveUserBotResult> {
  const path = `users/bots/${botId}`;
  return await makePutRestRequest<SaveUserBotParams, SaveUserBotResult>(
    path,
    params,
  );
}

export async function GetBacktestQueue(): Promise<GetBacktestQueueResult> {
  const path = `users/strategies/backtest/queue`;
  return await makeGetRestRequest<undefined, GetBacktestQueueResult>(
    path,
    undefined,
    'text/html',
  );
}
export async function GetReferralData(): Promise<GetReferralDetails> {
  const path = `referral/details`;
  return await makeGetRestRequest<undefined, GetReferralDetails>(
    path,
    undefined,
    'text/html',
  );
}
export async function PutCancelBacktestQueue(
  id: number,
): Promise<PutCancelBacktestQueueResult> {
  const path = `users/strategies/backtest/queue/${id}/cancel`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return await makePutRestRequest<
    PutCancelBacktestQueueParams,
    PutCancelBacktestQueueResult
  >(path, { cancel: true });
}

export async function GetUserBot(
  id: number,
  versionId?: string,
): Promise<GetUserBotResult> {
  const path = versionId
    ? `users/bots/${id}?version=${versionId}`
    : `users/bots/${id}`;
  return await makeGetRestRequest<undefined, GetUserBotResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function PostUserBotIsExist(
  params: PostUserBotIsExistParams,
): Promise<PostUserBotIsExistResult> {
  const path = `users/bots/isexist`;
  return await makePostRestRequest<
    PostUserBotIsExistParams,
    PostUserBotIsExistResult
  >(path, params);
}

export async function GetStrategyDataByUserStrategy(
  model: string,
  method: string,
  strategy_id: number,
): Promise<GetStrategyDataByUserStrategyResult> {
  const params: GetStrategyDataByUserStrategyParms = {
    args: {},
  };
  const response = await RestRequest<
    GetStrategyDataByUserStrategyParms,
    GetStrategyDataByUserStrategyResult
  >(
    `object/${model}/${strategy_id}/getStrategyDetail`,
    params,
    method,
    'application/json',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.title);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetProfitLossByDate(
  model: string,
  startDate: string,
  endDate: string,
  withFees: number,
  period?: string,
  withPercentage?: boolean,
): Promise<GetProfitLossByDateResult> {
  const path = `${model}/profitloss?start_date=${startDate}&end_date=${endDate}&with_fees=${withFees}&time_period=${
    period ? period : 'day'
  }${withPercentage ? '&with_percentage=1' : ''}`;
  return await makeGetRestRequest<undefined, GetProfitLossByDateResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function GetAuthor(id: number): Promise<GetAuthorResult> {
  const path = `authors/${id}`;
  const resProfile = await makeGetRestRequest<undefined, GetAuthorResult>(
    path,
    undefined,
    'text/html',
  );
  if (resProfile && resProfile.name) {
    const resPicture = await makeGetRestRequest<undefined, AuthorPictureResult>(
      `${path}/picture`,
      undefined,
      'text/html',
    );
    if (resPicture && resPicture.picture)
      resProfile.picture = resPicture.picture;
  }
  return resProfile;
}

export async function GetEvents(
  eventType?: string,
  platform?: boolean,
): Promise<GetEventsResult> {
  let path = `events?type=scheduled&event_type=${eventType}`;
  if (platform === undefined) {
    path = `events?type=scheduled&event_type=${eventType}`;
  } else {
    path = `events?type=scheduled&event_type=${eventType}&platform=${
      platform ? 'web' : 'ios'
    }`;
  }

  const resProfile = await makeGetRestRequest<undefined, GetEventsResult>(
    path,
    undefined,
    'text/html',
  );
  return resProfile;
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function GetSiteInfo(): Promise<GetSiteInfoResult> {
  const defaultData = {
    name: '',
    logo: '',
    small_logo: '',
    favicon: '',
    razorpay_key: '',
    term_link: '',
  } as GetSiteInfoResult;

  try {
    const path = `siteinfo`;
    const resProfile = await makeGetRestRequest<undefined, GetSiteInfoResult>(
      path,
      undefined,
      'text/html',
    );

    if (!resProfile || !resProfile.name) return defaultData;

    return resProfile;
  } catch {
    // do nothing just send blank
  }

  return defaultData;

  // if ((process.env.REACT_APP_IS_WHITELABEL_MARKETSTAR as string) === 'true')
  //   return {
  //     name: 'MarketStar',
  //     logo: '/MarketStar/mainlogo.png',
  //     small_logo: '/MarketStar/favicon40.png',
  //     favicon: '/MarketStar/favicon40.png',
  //     razorpay_key: 'rzp_test_UcnonfkrZdQc2W',
  //     razorpay_key_test: 'rzp_test_UcnonfkrZdQc2W',
  //     razorpay_key_live: 'rzp_live_OMliuPdZlmNt2v',
  //     term_link: 'https://speedbot.tech/terms',
  //   } as GetSiteInfoResult;
  // return {
  //   name: 'SpeedBot',
  //   logo: '/mainLogo.svg',
  //   small_logo: '/logo.svg',
  //   favicon: '/logo.svg',
  //   razorpay_key: 'rzp_test_UcnonfkrZdQc2W',
  //   razorpay_key_test: 'rzp_test_UcnonfkrZdQc2W',
  //   razorpay_key_live: 'rzp_live_OMliuPdZlmNt2v',
  //   term_link: 'https://speedbot.tech/terms',
  // } as GetSiteInfoResult;
}

export async function GetInvoices(
  page: number,
  pageSize: number,
): Promise<GetInvoicesResult> {
  const response = await RestRequest<GetInvoicesParams, GetInvoicesResult>(
    `subscriptions/invoices?page=${page}&page_size=${pageSize}`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function DownloadRestRequest<P, R>(
  path: string,
  params: P = {} as P,
  method: string,
  contentType = 'text/html',
): Promise<{ result?: R; error?: string }> {
  try {
    try {
      let session_id = getCookie(SESSION_ID) as string;
      if (!session_id) {
        session_id = '';
      }
      const headers = {
        'Content-Type': contentType,
        'X-Openerp-Session-Id': session_id,
      };

      let response;
      try {
        if (contentType === 'text/html') {
          response = await timeout(
            API_FETCH_TIMEOUT,
            fetch(`${API_BASE_URL}/${path}`, {
              method: method,
              headers: headers,
            }),
          );
        } else {
          const fetchOptions: FetchOptionType = {
            method: method,
            headers: headers,
          };
          if (
            params &&
            (method.toLowerCase() === 'post' || method.toLowerCase() === 'put')
          )
            fetchOptions.body = BuildRestRequest(params);

          response = await timeout(
            API_FETCH_TIMEOUT,
            fetch(`${API_BASE_URL}/${path}`, fetchOptions),
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if ((err && err.message === 'TIMEOUT') || !navigator.onLine)
          return { error: '' };
        return {
          error: '',
        };
      }

      if (!response.ok) {
        // eslint-disable-next-line
        const res = await response.json();
        // eslint-disable-next-line
        return { error: res.error };
      } else {
        // eslint-disable-next-line
        return { result: response.blob() };
      }
    } catch (err) {
      return {
        error: 'Unknown error. Please try again.',
      };
    }
  } catch (error) {
    return {
      error: 'Api request error',
    };
  }
}

export async function DownloadInvoice(invoice_id: number) {
  const response = await DownloadRestRequest<undefined, Blob>(
    `subscriptions/invoices/${invoice_id}/download`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function ActivateFreeTrial(): Promise<ActivateFreeTrialResult> {
  const response = await makeGetRestRequest<undefined, ActivateFreeTrialResult>(
    `subscriptions/free-trial/activate`,
    undefined,
    'text/html',
  );
  if (response.success) {
    return response;
  } else if (response.error) {
    throw new Error(response.error);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function WalletAction(
  action: string,
  amount: number,
  paymentAcquirer: string,
): Promise<WalletActionResult> {
  const path = `users/credits`;
  const params: WalletActionParams = {
    action,
    amount,
    payment_acquirer: paymentAcquirer,
  };
  const response = await RestRequest<WalletActionParams, WalletActionResult>(
    path,
    params,
    'POST',
    'application/json',
  );
  if (response.result?.success) {
    return response.result;
  } else if (response?.result?.error) {
    throw new Error(response.result.error);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function WalletPaymentStatus(
  paymentId: number,
  razorpayOrderId: string,
  paymentAcquirer: string,
): Promise<WalletStatusResult> {
  const path = `users/credits/status`;
  const params: WalletStatusParams = {
    payment_id: paymentId,
    payment_acquirer: paymentAcquirer,
    razorpay_order_id: razorpayOrderId,
  };
  const response = await RestRequest<WalletStatusParams, WalletStatusResult>(
    path,
    params,
    'POST',
    'application/json',
  );
  if (response.result?.success) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetWalletTransactions(
  page: number,
  pageSize: number,
): Promise<GetWalletTransactionResult> {
  const response = await RestRequest<undefined, GetWalletTransactionResult>(
    `users/wallet-transaction-history?page=${page}&page_size=${pageSize}`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetBotBuilderForm(): Promise<GetBotBuilderFormResult> {
  const response = await RestRequest<undefined, GetBotBuilderFormResult>(
    'bot-builder/config/form',
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function ReferralValidate(
  referral_code: string,
): Promise<ReferralValidateResult> {
  const response = await RestRequest<null, ReferralValidateResult>(
    `validate/referral_code/${referral_code}`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
export async function SetReferral(
  set_referral: string,
): Promise<SetReferralResult> {
  const response = await RestRequest<null, SetReferralResult>(
    `set/referrer/${set_referral}`,
    undefined,
    'GET',
    'text/html',
    true,
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetReportUserActivity(
  activity: string,
  data?: Record<string, string>,
): Promise<{ success: boolean }> {
  let path = `set/user/activity?action=${activity}`;
  if (data && Object.keys(data).length > 0) {
    const params = new URLSearchParams({ ...data });
    path += `&${params.toString()}`;
  }
  const response = await RestRequest<undefined, { success: boolean }>(
    path,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetEstimatedCredits(
  action: string,
  otherParams?: Record<string, string>,
): Promise<GetEstimatedCreditResult> {
  const params = new URLSearchParams({ action, ...otherParams });
  const path = `users/estimated_credits?${params.toString()}`;
  const response = await RestRequest<undefined, GetEstimatedCreditResult>(
    path,
    undefined,
    'GET',
    'text/html',
    true,
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetLogs(
  Id: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type: string,
): Promise<GetBotsLogResult> {
  const url = `https://dev-app.speedbot.tech/code-server/logger/${Id}/logs`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
      },
    });

    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const errorBody = await response.json();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      throw new Error(errorBody?.message || 'Failed to fetch logs');
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result: GetBotsLogResult = await response.json();
    return result;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Unknown error. Please try again.');
  }
}

export async function PostAction(
  action?: string | undefined,
  botid?: number,
): Promise<GetOtpResult> {
  const params: GetActionParams = {
    action: action,
  };
  const response = await RestRequest<GetActionParams, GetOtpResult>(
    `users/strategy/${botid}/backtest/pod`,
    params,
    'POST',
    'application/json',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error, Please try again.');
  }
}

export async function GetPaidAmount(
  credit_amount: number,
): Promise<GetPaidAmountResult> {
  const response = await RestRequest<null, GetPaidAmountResult>(
    `users/estimated_price?credit_amount=${credit_amount}`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
export async function GetFilterMetaData(): Promise<GetReportDropDownData> {
  const response = await RestRequest<null, GetReportDropDownData>(
    `history/report/meta/filters`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
export async function PostCreditTransfer(
  action: string,
  creditAmount: number,
  transferUserAccount: string,
): Promise<PostCreditTransferResult> {
  const path = 'users/credits';
  const param: PostCreditTransferData = {
    action: action,
    amount: creditAmount,
    to_email: transferUserAccount,
  };
  return makePostRestRequest<PostCreditTransferData, PostCreditTransferResult>(
    path,
    param,
  );
}
export async function GetPortfolioShare(
  userId: number,
  todayDate: string,
): Promise<GetPortfolioShareResult> {
  const path = `portfolio/share?user_id=${userId}&date=${todayDate}`;
  return await makeGetRestRequest<undefined, GetPortfolioShareResult>(
    path,
    undefined,
    'text/html',
  );
}
export async function PostPortfolioShare(
  params: PostPortfolioShareParams,
): Promise<PostPortfolioShareResults> {
  const path = `portfolio/share`;
  return await makePostRestRequest<
    PostPortfolioShareParams,
    PostPortfolioShareResults
  >(path, params);
}

export async function GetStrategyParameterUrl(
  UrlPath?: string,
  exchange?: string,
): Promise<GetStrategtParameterKeyValueResult> {
  let path = '';
  if (exchange) {
    path += `${UrlPath}?keys=${exchange}`;
  } else {
    path += `${UrlPath}`;
  }
  return await makeGetRestRequest<
    undefined,
    GetStrategtParameterKeyValueResult
  >(path, undefined, 'text/html');
}

export async function GetAccountDelete(): Promise<GetAccountDeleteResult> {
  const response = await RestRequest<null, GetAccountDeleteResult>(
    `delete/user`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
export async function GetTicketsData(): Promise<GetTicketsDataResult> {
  const response = await RestRequest<null, GetTicketsDataResult>(
    `helpdesk/tickets`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
export async function PostTicketsData(
  subject: string,
  description: string,
  file: PostTicketAttachmentData[],
): Promise<PostTicketDataResult> {
  const path = '/helpdesk/tickets';
  const param: PostTicketData = {
    subject: subject,
    description: description,
    attachments: file,
  };
  return makePostRestRequest<PostTicketData, PostTicketDataResult>(path, param);
}
export async function GetTicketMessageData(
  id: number,
): Promise<GetTicketMessageResult> {
  const response = await RestRequest<null, GetTicketMessageResult>(
    `helpdesk/tickets/${id}`,
    undefined,
    'GET',
    'text/html',
  );

  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
export async function PostTicketMessagedata(
  message: string,
  id: number,
  file: PostTicketAttachmentData[],
  action: string,
): Promise<PostTicketMessageDataResponse> {
  const path = `/helpdesk/tickets/${id}`;
  const param: PostTicketMessageData = {
    message: message,
    attachments: file,
    action: action,
  };
  return makePostRestRequest<
    PostTicketMessageData,
    PostTicketMessageDataResponse
  >(path, param);
}
// export async function DownloadTicketData(
//   ticketId: number,
//   messageIndex: number,
//   attachmentIndex: number,
// ) {
//   const response = await DownloadRestRequest<undefined, GetTicketMessageResult>(
//     `helpdesk/tickets/${ticketId}`,
//     undefined,
//     'GET',
//     'text/html',
//   );
//   if (response.result) {
//     return response.result.data.messages[messageIndex].attachments[
//       attachmentIndex
//     ].data;
//   } else if (response.error) {
//     throw new Error(response.error);
//   } else {
//     throw new Error('Unknown error. Please try again.');
//   }
// }
export async function GetHomePageData(): Promise<GetHomePageDataResult> {
  const response = await RestRequest<null, GetHomePageDataResult>(
    `home`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetLogout(): Promise<GetLogoutResult> {
  const response = await RestRequest<null, GetLogoutResult>(
    'logout',
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
export async function PostPortfolioManualBot(
  broker: string,
  capital: string,
  duration: string,
  multipler?: string,
  bot_name?: string,
  type?: string,
): Promise<PostPortfolioManualBotResults> {
  const params: PostPortfolioManualBotParams = {
    bot_name: bot_name,
    broker: broker,
    capital: capital,
    duration: duration,
    multiplier: multipler,
    type: type,
  };
  const path = `users/bots/manual`;
  return await makePostRestRequest<
    PostPortfolioManualBotParams,
    PostPortfolioManualBotResults
  >(path, params);
}
export async function GetPortfolioBotsV2(
  ptype: string,
): Promise<GetPortfolioBotResultV2> {
  const response = await RestRequest<null, GetPortfolioBotResultV2>(
    `portfolio/v2?category=${ptype}`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetOptionHelpData(): Promise<GetOptionHelpResult> {
  const response = await RestRequest<null, GetOptionHelpResult>(
    `strategy_builder/help`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetLogsFileData(
  id: number,
  file: string,
): Promise<GetBotsLogFilesDataResult> {
  const url = file
    ? `/user/bot/${id}/logs?filename=${file}`
    : `/user/bot/${id}/logs`;
  const response = await RestRequest<null, GetBotsLogFilesDataResult>(
    url,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('error in Get log files');
  }
}

export async function GetWebHookAuthKey(): Promise<GetWebHookAuthKeyResult> {
  const response = await RestRequest<null, GetWebHookAuthKeyResult>(
    `auth_key`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
export async function GetAllTodaysPositions(
  ptype = 'live',
): Promise<Position[]> {
  const path = `user/bot/today/positions?type=${ptype}`;
  return await makeGetRestRequest<undefined, Position[]>(
    path,
    undefined,
    'text/html',
  );
}
export async function GetBotType(id?: number): Promise<GetBotTypeResult> {
  const response = await RestRequest<null, GetBotTypeResult>(
    `bot/type/${id}`,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetOptionChainMetadata(
  date: string,
): Promise<GetOptionChainMetadataResult> {
  const response = await RestRequest<null, GetOptionChainMetadataResult>(
    `meta-data?date=${date}`,
    undefined,
    'GET',
    'text/html',
    false,
    '/hs',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
  // // eslint-disable-next-line
  // const result: GetOptionChainMetadataResult = await fetch(
  //   `/hs/meta-data?date=${date}`,
  //   { method: 'GET' },
  // ).then(res => res.json());
  // return result;
}

export async function GetOptionChainSymboldata(
  symbol: string,
  expiry: string,
  candle_time: string,
): Promise<GetOptionChainResultDataType> {
  const response = await RestRequest<null, GetOptionChainDataResult>(
    `option-chain/${symbol}?expiry=${expiry}&candle_time=${candle_time}`,
    undefined,
    'GET',
    'text/html',
    false,
    '/hs',
  );
  if (response.result) {
    return response.result.data;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
  // // eslint-disable-next-line
  // const result = await fetch(
  //   `/hs/option-chain/${symbol}?expiry=${expiry}&candle_time=${candle_time}`,
  //   { method: 'GET' },
  // ).then(res => res.json());
  // return result.data;
}

export async function PostTradeIdeasData(
  filter: TradeIdeasFilterParam,
): Promise<GetTradeIdeasResult> {
  const response = await RestRequest<
    TradeIdeasFilterParam,
    GetTradeIdeasResult
  >(`trade/table`, filter, 'post', 'application/json', false, '/hs');
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetTradeOptionData(
  symbol: string,
  view?: string,
): Promise<GetTradeOptionsResult> {
  const response = await RestRequest<null, GetTradeOptionsResult>(
    `trade/ideas?index=${symbol}&view=${view}`,
    undefined,
    'GET',
    'text/html',
    false,
    '/hs',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetTradeIdeasMetadata(): Promise<GetTradesIdeasMetadataResult> {
  const response = await RestRequest<null, GetTradesIdeasMetadataResult>(
    `trade/ideas/meta-data`,
    undefined,
    'GET',
    'text/html',
    false,
    '/hs',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
export async function PostImportFileData(
  formData: PostStrategyDataPickerProps,
): Promise<ImportStrategyResult> {
  const path = 'strategy/import';
  const param = formData;
  return makePostRestRequest<PostStrategyDataPickerProps, ImportStrategyResult>(
    path,
    param,
  );
}

export async function GetMTMData(botId: string): Promise<GetMTMDataResult> {
  const path = `bot/${Number(botId)}/mtm`;
  return await makeGetRestRequest<undefined, GetMTMDataResult>(
    path,
    undefined,
    'text/html',
  );
}
export async function iframUrl(
  strategy_id: string,
  method: string,
): Promise<GetFetchIframUrlResult> {
  const response = await RestRequest<undefined, GetFetchIframUrlResult>(
    `code_editor/strategy/${strategy_id}`,
    undefined,
    method,
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetUserActiveStatus(
  strategy_id: number,
): Promise<GetUserActiveStatusResult> {
  const path = `set/code_editor/activity/${strategy_id}`;

  const response = await RestRequest<undefined, { success: boolean }>(
    path,
    undefined,
    'GET',
    'text/html',
  );
  if (response.result) {
    return response.result;
  } else if (response.error) {
    throw new Error(response.error.message);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
export async function GetOptionSymbols(
  exchange?: string,
): Promise<TGetOptionSymbolsResult> {
  let path = '';
  if (exchange) {
    path += `pick-stocks?keys=${exchange}`;
  } else {
    path += `pick-stocks?keys=NSE`;
  }
  return await makeGetRestRequest<undefined, TGetOptionSymbolsResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function GetWebHookSignalBot(): Promise<TWebHookBotResult> {
  const path = 'external/signal/bots';

  return await makeGetRestRequest<undefined, TWebHookBotResult>(
    path,
    undefined,
    'text/html',
  );
}
export async function GetWebhookSignalDetails(
  botId: number,
): Promise<TWebHookSignalResult> {
  const path = `bot/${botId}/webhook/signals`;

  return await makeGetRestRequest<undefined, TWebHookSignalResult>(
    path,
    undefined,
    'text/html',
  );
}

export async function PutWebhookSignalAction(
  params: TWebHookActionParams,
  subId: number,
): Promise<TWebHookActionResult> {
  const path = `external/bot/${subId}`;
  return await makePutRestRequest<TWebHookActionParams, TWebHookActionResult>(
    path,
    params,
  );
}

export async function PostTOTPVarification(
  params: TOTPVarificationParams,
  id: number,
): Promise<TOTPVarificationResult> {
  const path = `totp/${id}/verify`;
  return await makePostRestRequest<
    TOTPVarificationParams,
    TOTPVarificationResult
  >(path, params);
}
