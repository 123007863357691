import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Grid,
  OutlinedInput,
  Alert,
} from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { formatNumber } from 'utils/GenericFunctions';
import { GoLiveFormProps } from './BotDeployModal';
import useGetMarketplaceBotDetail from 'services/Marketplace/useGetMarketplaceBotDetail';
import useGetBrokers from 'services/Menu/useGetBrokers';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { manipulateAddBrokerRef } from './util';

const botLiveDurations = [
  '1 Week',
  '2 Weeks',
  '3 Weeks',
  '4 Weeks',
  '1 Month',
  '2 Months',
  '3 Months',
  '6 Months',
  '12 Months',
];

export default function BotDeployForm({
  botName,
  botId,
  isCrypto,
  setStepNumber,
  exchanges,
  setFormData,
  onclose,
}: {
  botName: string;
  botId: number;
  isCrypto?: boolean;
  exchanges?: string[];
  setStepNumber: (val: number) => void;
  setFormData: (values: GoLiveFormProps | null) => void;
  onclose: () => void;
}) {
  const exchangeMapping: Record<string, string> = {
    USA: 'USA',
    CCXT: 'crypto',
    FOREX: 'FOREX',
    CRYPTO_INDIA: 'crypto',
    CRYPTO_GLOBAL: 'crypto',
  };

  const brokerParams: string = exchangeMapping[String(exchanges)] || 'NSE';

  const { t } = useTranslation();
  const { data: brokersData, isLoading: getBrokerLoaing } =
    useGetBrokers(brokerParams);
  const {
    data: marketplaceBotDetailData,
    isLoading: getMarketplaceDetailLoaing,
  } = useGetMarketplaceBotDetail(botId);

  // const isCrypto = true;

  const isLoading = getBrokerLoaing || getMarketplaceDetailLoaing;
  return (
    <>
      <Formik
        initialValues={
          {
            broker: '',
            duration: '',
            amount: '',
            durationType: 'Weeks',
            capital: '',
            multiple: '',
          } as GoLiveFormProps
        }
        validationSchema={Yup.object().shape({
          broker: Yup.string().required(
            t('bot_deploy_modal.valid_msg.req', { type: 'Broker' }),
          ),
          duration: Yup.string().required(
            t('bot_deploy_modal.valid_msg.req', { type: 'Duration' }),
          ),
          capital: Yup.string().required(
            t('bot_deploy_modal.valid_msg.req', { type: 'Capital' }),
          ),
          amount: Yup.number().when('duration', {
            is: value => value === 'Manually',
            then: Yup.number()
              .required(t('bot_deploy_modal.valid_msg.req', { type: 'Count' }))
              .positive()
              .min(1, t('bot_deploy_modal.valid_msg.valid', { type: 'Count' }))
              .test(
                t('bot_deploy_modal.valid_msg.is'),
                t('bot_deploy_modal.valid_msg.valid', { type: 'Count' }),
                value => value !== undefined && Number.isInteger(value),
              ),
          }),
          multiple: Yup.number().when('capital', {
            is: value => value === 'Manually',
            then: Yup.number()
              .required(
                t('bot_deploy_modal.valid_msg.req', { type: 'Multiple' }),
              )
              .positive()
              .when({
                is: () => !isCrypto,
                then: schema =>
                  schema.test(
                    t('bot_deploy_modal.valid_msg.is'),
                    t('bot_deploy_modal.valid_msg.valid', { type: 'Multiple' }),
                    value => value !== undefined && Number.isInteger(value),
                  ),
                otherwise: schema =>
                  schema.test(
                    t('bot_deploy_modal.valid_msg.is'),
                    t('bot_deploy_modal.valid_msg.valid', { type: 'Multiple' }),
                    value => value !== undefined && value > 0,
                  ),
              }),
            // .min(
            //   1,
            //   t('bot_deploy_modal.valid_msg.valid', { type: 'multiple' }),
            // )
            // .test(
            //   t('bot_deploy_modal.valid_msg.is'),
            //   t('bot_deploy_modal.valid_msg.valid', { type: 'Multiple' }),
            //   value => value !== undefined && Number.isInteger(value),
            // ),
          }),
        })}
        onSubmit={(values, actions) => {
          setStepNumber(3);
          setFormData(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
        }) => (
          <Box
            className="m-main-spacing"
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <CircularLoader open={isLoading} />
            <Box sx={{ mb: 2, flex: 1 }}>
              <Typography variant="body1" sx={{ pb: 1, fontWeight: 400 }}>
                <FormattedMessage id="bot_deploy_modal.going_live" />
              </Typography>
              <Typography
                variant="body1"
                sx={{ pb: 3, display: 'flex', fontWeight: 600 }}
                component="span"
              >
                {botName}
              </Typography>
              {!brokersData || brokersData.length === 0 ? (
                <Alert severity="error" sx={{ mb: 4 }}>
                  <FormattedMessage id="bot_deploy_modal.link_your_broker_account" />
                  <RouterLink
                    to="/brokers/new"
                    onClick={() => {
                      manipulateAddBrokerRef('set', '?action=deploy');
                    }}
                    style={{ textDecoration: 'underline' }}
                  >
                    <FormattedMessage id="bot_deploy_modal.here" />
                  </RouterLink>
                </Alert>
              ) : (
                <FormControl
                  fullWidth
                  sx={{ mb: 4 }}
                  error={Boolean(touched.broker && errors.broker)}
                >
                  <InputLabel id="label-broker">
                    <FormattedMessage id="common.broker" />
                  </InputLabel>
                  <Select
                    labelId="label-broker"
                    name="broker"
                    value={values.broker}
                    label={<FormattedMessage id="common.broker" />}
                    onChange={e => {
                      setFieldValue('broker', e.target.value);
                    }}
                  >
                    {brokersData &&
                      brokersData.map(broker => (
                        <MenuItem key={broker.id} value={broker.id}>
                          {broker.broker} ({broker.username})
                        </MenuItem>
                      ))}
                  </Select>
                  {touched.broker && errors.broker && (
                    <FormHelperText error>{errors.broker}</FormHelperText>
                  )}
                </FormControl>
              )}

              <FormControl
                fullWidth
                sx={{ mb: 4 }}
                error={Boolean(touched.duration && errors.duration)}
              >
                <InputLabel id="label-duration">
                  <FormattedMessage id="bot_deploy_modal.duration" />
                </InputLabel>
                <Select
                  labelId="label-duration"
                  name="duration"
                  value={values.duration}
                  label={<FormattedMessage id="bot_deploy_modal.duration" />}
                  onChange={e => {
                    setFieldValue('duration', e.target.value);
                  }}
                >
                  {botLiveDurations.map((duration, i) => (
                    <MenuItem value={duration} key={i}>
                      {duration}
                    </MenuItem>
                  ))}
                  <MenuItem value="Manually">
                    <FormattedMessage id="bot_deploy_modal.set_manually" />
                  </MenuItem>
                </Select>
                {touched.duration && errors.duration && (
                  <FormHelperText error>{errors.duration}</FormHelperText>
                )}
              </FormControl>
              {values.duration === 'Manually' && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.amount && errors.amount)}
                      variant="outlined"
                      sx={{ mb: 4 }}
                    >
                      <InputLabel>
                        <FormattedMessage id="bot_deploy_modal.duration_count" />
                      </InputLabel>
                      <OutlinedInput
                        name="amount"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.amount}
                        label={
                          <FormattedMessage id="bot_deploy_modal.duration_count" />
                        }
                        inputProps={{ min: 1 }}
                      />
                      {touched.amount && errors.amount && (
                        <FormHelperText error>{errors.amount}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 4 }}>
                      <InputLabel id="label-durationType">
                        <FormattedMessage id="bot_deploy_modal.duration_type" />
                      </InputLabel>
                      <Select
                        labelId="label-durationType"
                        name="durationType"
                        value={values.durationType}
                        label={
                          <FormattedMessage id="bot_deploy_modal.duration_type" />
                        }
                        onChange={e => {
                          setFieldValue('durationType', e.target.value);
                        }}
                      >
                        <MenuItem value="Days">
                          <FormattedMessage id="bot_deploy_modal.days" />
                        </MenuItem>
                        <MenuItem value="Weeks">
                          <FormattedMessage id="bot_deploy_modal.weeks" />
                        </MenuItem>
                        <MenuItem value="Months">
                          <FormattedMessage id="bot_deploy_modal.months" />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <FormControl
                fullWidth
                sx={{ mb: 4 }}
                error={Boolean(touched.capital && errors.capital)}
              >
                <InputLabel id="label-capital">
                  <FormattedMessage id="bot_deploy_modal.capital" />
                </InputLabel>
                <Select
                  labelId="label-capital"
                  name="capital"
                  value={values.capital}
                  label={<FormattedMessage id="bot_deploy_modal.capital" />}
                  onChange={e => {
                    setFieldValue('capital', e.target.value);
                  }}
                >
                  {marketplaceBotDetailData &&
                    [...(Array(10) as Array<number>)].map((e, i) => (
                      <MenuItem
                        value={
                          marketplaceBotDetailData.initial_capital * (i + 1)
                        }
                        key={i}
                      >
                        {marketplaceBotDetailData.currency_symbol}
                        {formatNumber(
                          marketplaceBotDetailData.initial_capital * (i + 1),
                          false,
                          0,
                        )}
                      </MenuItem>
                    ))}
                  <MenuItem value="Manually">
                    <FormattedMessage id="bot_deploy_modal.set_manually" />
                  </MenuItem>
                </Select>
                {touched.capital && errors.capital && (
                  <FormHelperText error>{errors.capital}</FormHelperText>
                )}
              </FormControl>
              {values.capital === 'Manually' && (
                <Grid container spacing={2}>
                  <Grid item xs={6} display="flex" justifyContent="end">
                    <Typography
                      variant="body1"
                      sx={{ display: 'flex', fontWeight: 400, pt: 2 }}
                    >
                      {marketplaceBotDetailData?.currency_symbol}
                      {formatNumber(
                        marketplaceBotDetailData?.initial_capital as number,
                        false,
                        0,
                      )}
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ pl: 1, fontWeight: 400 }}
                      >
                        X
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.multiple && errors.multiple)}
                      variant="outlined"
                      sx={{ mb: 4 }}
                    >
                      <InputLabel>
                        <FormattedMessage id="bot_deploy_modal.multiple" />
                      </InputLabel>
                      <OutlinedInput
                        name="multiple"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.multiple}
                        label={
                          <FormattedMessage id="bot_deploy_modal.multiple" />
                        }
                        inputProps={{ min: 1 }}
                      />
                      {touched.multiple && errors.multiple && (
                        <FormHelperText error>{errors.multiple}</FormHelperText>
                      )}
                      {!(touched.multiple && errors.multiple) &&
                        values.multiple && (
                          <Typography variant="body1" sx={{ pt: 2 }}>
                            = {marketplaceBotDetailData?.currency_symbol}
                            {formatNumber(
                              (marketplaceBotDetailData?.initial_capital as number) *
                                Number(values.multiple ? values.multiple : 0),
                              false,
                              0,
                            )}
                          </Typography>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box sx={{ mb: 0 }}>
              <MuiButton
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                sx={{ mb: 2 }}
                disabled={false}
              >
                <FormattedMessage id="buttons.continue" />
              </MuiButton>
              <MuiButton
                variant="contained"
                color="secondary"
                fullWidth
                onClick={onclose}
              >
                <FormattedMessage id="buttons.cancel" />
              </MuiButton>
            </Box>
          </Box>
        )}
      </Formik>
    </>
  );
}
