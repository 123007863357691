import * as Yup from 'yup';

import MuiFullScreenModal from '../Auth/SignInFullScreenModal';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { GoLiveFormProps } from '../Bots/BotDetail/BotDeployModalContent';
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { manipulateAddBrokerRef } from '../Bots/BotDetail/util';
import { formatNumber } from 'utils/GenericFunctions';
import MuiButton from 'app/design/uiComponents/MuiButton';
import useGetBrokers from 'services/Menu/useGetBrokers';
import { Link as RouterLink } from 'react-router-dom';
import usePostManualBot from 'services/Portfolio/usePostManualBot';
import moment from 'moment';
import { useState } from 'react';
import { useGetMTVPSTErminalStatus } from 'services/Menu/useGetMTVPSTerminal';
import useGetBrokerById from 'services/Menu/useGetBrokerById';
// const botLiveDurations = [
//   '1 Week',
//   '2 Weeks',
//   '3 Weeks',
//   '4 Weeks',
//   '1 Month',
//   '2 Months',
//   '3 Months',
//   '6 Months',
//   '12 Months',
// ];
const initial_capital = 170000;
type ManualBotModalTypes = {
  open: boolean;
  handleClose: () => void;
  source?: string;
};
const ManualBotModal = ({ open, handleClose, source }: ManualBotModalTypes) => {
  const handleModalClose = () => {
    handleClose();
  };
  const { t } = useTranslation();
  const { data: brokersData, isLoading: getBrokerLoaing } = useGetBrokers();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bringData, setBringData] = useState<GoLiveFormProps>({
    duration: '1 Week',
  } as GoLiveFormProps);
  const manualBotMutation = usePostManualBot();
  const [ubiID, setUbiID] = useState<number | null>(null);
  const { data: brokerData } = useGetBrokerById(Number(ubiID));
  const { data } = useGetMTVPSTErminalStatus(ubiID, brokerData?.environment);

  const checkBrokerTerminalStatus = (id: number) => {
    if (id) {
      setUbiID(id);
    }
  };
  //   const {
  //     data: marketplaceBotDetailData,
  //     isLoading: getMarketplaceDetailLoaing,
  //   } = useGetMarketplaceBotDetail(botId);
  const isLoading = getBrokerLoaing;
  const curDate = moment(new Date());
  let durationDays = 0;
  const duration = bringData.duration.split(' ');
  if (bringData.duration === 'Manually') {
    const dt = moment().add(
      Number(bringData.amount),
      (
        bringData.durationType as string
      ).toLowerCase() as moment.unitOfTime.DurationConstructor,
    );
    durationDays = dt.diff(curDate, 'days');
  } else if (duration[1] === 'Days' || duration[1] === 'Day') {
    durationDays = parseInt(duration[0]);
  } else if (duration[1] === 'Weeks' || duration[1] === 'Week') {
    durationDays = parseInt(duration[0]) * 7;
  } else if (duration[1] === 'Months' || duration[1] === 'Month') {
    const dt = moment(new Date()).add(parseInt(duration[0]), 'months');
    durationDays = dt.diff(curDate, 'days');
  }

  return (
    <MuiFullScreenModal
      open={open}
      title="Manual Bot"
      handleClose={handleModalClose}
      body={
        <>
          <Formik
            initialValues={
              {
                botName: '',
                broker: '',
                duration: '12 Months',
                amount: '',
                durationType: 'Months',
                capital: '100000',
                multiple: '',
              } as GoLiveFormProps
            }
            validationSchema={Yup.object().shape({
              botName: Yup.string().required(
                t('user_backtesting.valid_msg.req', {
                  name: t('user_bot_startegy.form.bot_name'),
                }),
              ),
              broker: Yup.string().required(
                t('bot_deploy_modal.valid_msg.req', { type: 'Broker' }),
              ),
              duration: Yup.string().required(
                t('bot_deploy_modal.valid_msg.req', { type: 'Duration' }),
              ),
              amount: Yup.number().when('duration', {
                is: value => value === 'Manually',
                then: Yup.number()
                  .required(
                    t('bot_deploy_modal.valid_msg.req', { type: 'Count' }),
                  )
                  .positive()
                  .min(
                    1,
                    t('bot_deploy_modal.valid_msg.valid', { type: 'Count' }),
                  )
                  .test(
                    t('bot_deploy_modal.valid_msg.is'),
                    t('bot_deploy_modal.valid_msg.valid', { type: 'Count' }),
                    value => value !== undefined && Number.isInteger(value),
                  ),
              }),
              multiple: Yup.number().when('capital', {
                is: value => value === 'Manually',
                then: Yup.number()
                  .required(
                    t('bot_deploy_modal.valid_msg.req', { type: 'Multiple' }),
                  )
                  .positive()
                  .when({
                    is: () => true,
                    then: schema =>
                      schema.test(
                        t('bot_deploy_modal.valid_msg.is'),
                        t('bot_deploy_modal.valid_msg.valid', {
                          type: 'Multiple',
                        }),
                        value => value !== undefined && Number.isInteger(value),
                      ),
                    otherwise: schema =>
                      schema.test(
                        t('bot_deploy_modal.valid_msg.is'),
                        t('bot_deploy_modal.valid_msg.valid', {
                          type: 'Multiple',
                        }),
                        value => value !== undefined && value > 0,
                      ),
                  }),
                // .min(
                //   1,
                //   t('bot_deploy_modal.valid_msg.valid', { type: 'multiple' }),
                // )
                // .test(
                //   t('bot_deploy_modal.valid_msg.is'),
                //   t('bot_deploy_modal.valid_msg.valid', { type: 'Multiple' }),
                //   value => value !== undefined && Number.isInteger(value),
                // ),
              }),
            })}
            onSubmit={(values, actions) => {
              manualBotMutation.mutate(
                {
                  bot_name: values.botName,
                  broker: values.broker,
                  capital: values.capital,
                  duration: durationDays.toString(),
                  multiplier: values.multiple,
                  type: source == 'signal' ? 'external' : undefined,
                },
                {
                  onSuccess: () => handleClose(),
                },
              );
              actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
            }) => (
              <Box
                className="m-main-spacing"
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <CircularLoader open={isLoading} />
                <Box sx={{ mb: 2, flex: 1 }}>
                  <Typography variant="body1" sx={{ pb: 1, fontWeight: 400 }}>
                    <FormattedMessage id="bot_deploy_modal.going_live" />
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ pb: 3, display: 'flex', fontWeight: 600 }}
                    component="span"
                  >
                    {/* {botName} */}
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{ mb: 4 }}
                    error={Boolean(touched.botName && errors.botName)}
                  >
                    <InputLabel id="label-duration">Bot Name</InputLabel>

                    <OutlinedInput
                      id="botName"
                      type={'text'}
                      name="botName"
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('botName', e.target.value);
                      }}
                      label="botName"
                      inputProps={{}}
                    />
                    {touched.botName && errors.botName && (
                      <FormHelperText error>{errors.botName}</FormHelperText>
                    )}
                  </FormControl>
                  {!brokersData || brokersData.length === 0 ? (
                    <Alert severity="error" sx={{ mb: 4 }}>
                      <FormattedMessage id="bot_deploy_modal.link_your_broker_account" />
                      <RouterLink
                        to="/broker/new"
                        onClick={() => {
                          manipulateAddBrokerRef('set', '?action=deploy');
                        }}
                        style={{ textDecoration: 'underline' }}
                      >
                        <FormattedMessage id="bot_deploy_modal.here" />
                      </RouterLink>
                    </Alert>
                  ) : (
                    <FormControl
                      fullWidth
                      sx={{ mb: 4 }}
                      error={Boolean(touched.broker && errors.broker)}
                    >
                      <InputLabel id="label-broker">
                        <FormattedMessage id="common.broker" />
                      </InputLabel>
                      <Select
                        labelId="label-broker"
                        name="broker"
                        value={values.broker}
                        label={<FormattedMessage id="common.broker" />}
                        onChange={e => {
                          checkBrokerTerminalStatus(+e.target.value);
                          setFieldValue('broker', e.target.value);
                        }}
                      >
                        {brokersData &&
                          brokersData.map(broker => (
                            <MenuItem key={broker.id} value={broker.id}>
                              {broker.broker} ({broker.username})
                            </MenuItem>
                          ))}
                      </Select>
                      {brokerData &&
                        brokerData.environment == 'live-mt5' &&
                        data &&
                        !data.success && (
                          <FormHelperText error>
                            Please navigate to the Broker section and start the
                            MT5 terminal for this broker.
                          </FormHelperText>
                        )}
                      {touched.broker && errors.broker && (
                        <FormHelperText error>{errors.broker}</FormHelperText>
                      )}
                    </FormControl>
                  )}

                  {/* {source === 'signal' && (
                    <FormControl
                      fullWidth
                      sx={{ mb: 4 }}
                      error={Boolean(touched.duration && errors.duration)}
                    >
                      <InputLabel id="label-duration">
                        <FormattedMessage id="bot_deploy_modal.duration" />
                      </InputLabel>
                      <Select
                        labelId="label-duration"
                        name="duration"
                        value={values.duration}
                        label={
                          <FormattedMessage id="bot_deploy_modal.duration" />
                        }
                        onChange={e => {
                          setFieldValue('duration', e.target.value);
                          setBringData({ ...values, duration: e.target.value });
                        }}
                      >
                        {botLiveDurations.map((duration, i) => (
                          <MenuItem value={duration} key={i}>
                            {duration}
                          </MenuItem>
                        ))}
                        <MenuItem value="Manually">
                          <FormattedMessage id="bot_deploy_modal.set_manually" />
                        </MenuItem>
                      </Select>
                      {touched.duration && errors.duration && (
                        <FormHelperText error>{errors.duration}</FormHelperText>
                      )}
                    </FormControl>
                  )} */}
                  {values.duration === 'Manually' && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.amount && errors.amount)}
                          variant="outlined"
                          sx={{ mb: 4 }}
                        >
                          <InputLabel>
                            <FormattedMessage id="bot_deploy_modal.duration_count" />
                          </InputLabel>
                          <OutlinedInput
                            name="amount"
                            type="number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.amount}
                            label={
                              <FormattedMessage id="bot_deploy_modal.duration_count" />
                            }
                            inputProps={{ min: 1 }}
                          />
                          {touched.amount && errors.amount && (
                            <FormHelperText error>
                              {errors.amount}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <InputLabel id="label-durationType">
                            <FormattedMessage id="bot_deploy_modal.duration_type" />
                          </InputLabel>
                          <Select
                            labelId="label-durationType"
                            name="durationType"
                            value={values.durationType}
                            label={
                              <FormattedMessage id="bot_deploy_modal.duration_type" />
                            }
                            onChange={e => {
                              setFieldValue('durationType', e.target.value);
                            }}
                          >
                            <MenuItem value="Days">
                              <FormattedMessage id="bot_deploy_modal.days" />
                            </MenuItem>
                            <MenuItem value="Weeks">
                              <FormattedMessage id="bot_deploy_modal.weeks" />
                            </MenuItem>
                            <MenuItem value="Months">
                              <FormattedMessage id="bot_deploy_modal.months" />
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  {source !== 'signal' && (
                    <FormControl
                      fullWidth
                      sx={{ mb: 4 }}
                      error={Boolean(touched.capital && errors.capital)}
                    >
                      <InputLabel id="label-capital">
                        <FormattedMessage id="bot_deploy_modal.capital" />
                      </InputLabel>
                      <Select
                        labelId="label-capital"
                        name="capital"
                        value={values.capital}
                        label={
                          <FormattedMessage id="bot_deploy_modal.capital" />
                        }
                        onChange={e => {
                          setFieldValue('capital', e.target.value);
                        }}
                      >
                        {[...(Array(10) as Array<number>)].map((e, i) => (
                          <MenuItem value={initial_capital * (i + 1)} key={i}>
                            {'$'}
                            {formatNumber(initial_capital * (i + 1), false, 0)}
                          </MenuItem>
                        ))}
                        <MenuItem value="Manually">
                          <FormattedMessage id="bot_deploy_modal.set_manually" />
                        </MenuItem>
                      </Select>
                      {touched.capital && errors.capital && (
                        <FormHelperText error>{errors.capital}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                  {values.capital === 'Manually' && (
                    <Grid container spacing={2}>
                      <Grid item xs={6} display="flex" justifyContent="end">
                        <Typography
                          variant="body1"
                          sx={{ display: 'flex', fontWeight: 400, pt: 2 }}
                        >
                          ${formatNumber(initial_capital as number, false, 0)}
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ pl: 1, fontWeight: 400 }}
                          >
                            X
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.multiple && errors.multiple)}
                          variant="outlined"
                          sx={{ mb: 4 }}
                        >
                          <InputLabel>
                            <FormattedMessage id="bot_deploy_modal.multiple" />
                          </InputLabel>
                          <OutlinedInput
                            name="multiple"
                            type="number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.multiple}
                            label={
                              <FormattedMessage id="bot_deploy_modal.multiple" />
                            }
                            inputProps={{ min: 1 }}
                          />
                          {touched.multiple && errors.multiple && (
                            <FormHelperText error>
                              {errors.multiple}
                            </FormHelperText>
                          )}
                          {!(touched.multiple && errors.multiple) &&
                            values.multiple && (
                              <Typography variant="body1" sx={{ pt: 2 }}>
                                = {'$'}
                                {formatNumber(
                                  (initial_capital as number) *
                                    Number(
                                      values.multiple ? values.multiple : 0,
                                    ),
                                  false,
                                  0,
                                )}
                              </Typography>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                </Box>
                <Box sx={{ mb: 0 }}>
                  <MuiButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    sx={{ mb: 2 }}
                    disabled={
                      brokerData &&
                      brokerData.environment == 'live-mt5' &&
                      data &&
                      !data.success
                    }
                  >
                    {/* <FormattedMessage id="buttons.continue" /> */}
                    Start Live
                  </MuiButton>
                  <MuiButton
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleClose}
                  >
                    <FormattedMessage id="buttons.cancel" />
                  </MuiButton>
                </Box>
              </Box>
            )}
          </Formik>
        </>
      }
    />
  );
};

export default ManualBotModal;
