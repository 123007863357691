import { memo } from 'react';
import { Box } from '@mui/material';
import { ItemHead, ItemSeparator } from 'app/design/speedBot/EntityItem';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import OptionsBuilder from 'app/components/Builder/OptionsStrategy/OptionsBuilder';
import { SbStrategy } from '../StrategyBuilder/types';

const OptionalConditions = ({
  strategy,
  botBuilderType,
}: {
  strategy: SbStrategy;
  botBuilderType?: string;
}) => {
  // const [mode, setMode] = useState<string>(StrategyMode.VIEW);

  // const handleChangeMode = () => {
  //   if (mode === StrategyMode.VIEW) setMode(StrategyMode.TEXT);
  //   else setMode(StrategyMode.VIEW);
  // };

  return (
    <Box>
      <ItemHead
        // variant="mediumWithIcon"
        variant="medium"
        title={
          <>
            {botBuilderType?.toLowerCase() == 'mcx' ? (
              <FormattedMessage id="user_bot_startegy.commodity_conditions" />
            ) : botBuilderType?.toLowerCase() == 'crypto_india' ? (
              <FormattedMessage id="user_bot_startegy.crypto_india_conditions" />
            ) : botBuilderType?.toLowerCase() == 'crypto_global' ? (
              <FormattedMessage id="user_bot_startegy.crypto_global_conditions" />
            ) : (
              <FormattedMessage id="user_bot_startegy.option_conditions" />
            )}
          </>
        }
        // icon={
        //   <IconButton
        //     onClick={handleChangeMode}
        //     size="small"
        //     sx={{
        //       backgroundColor: theme =>
        //         mode === StrategyMode.TEXT
        //           ? 'transparent'
        //           : theme.palette.grey[300] + ' !important',
        //       borderRadius: 1,
        //     }}
        //   >
        //     <CodeIcon />
        //   </IconButton>
        // }
      />
      <Box className="m-main-spacing">
        <ItemSeparator variant="2px" />
      </Box>

      <Box className="m-main-spacing" sx={{ py: 2, mt: 2 }}>
        <OptionsBuilder
          setValidationMessage={() => {
            //
          }}
          optionBuilderData={strategy}
          isReadOnly={true}
          onChangeBuilderData={() => {
            // Content
          }}
        />
      </Box>
    </Box>
  );
};

export default memo(OptionalConditions);
